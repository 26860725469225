@import '../../../../styles/app.scss';

.root {
  display: flex;
  width: 16.79688rem;
  padding: 1rem;
  flex-direction: column;
  justify-content: center;
  border-radius: $border-radius;
  background-color: $primary;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: $gap-small;
    .value {
      color: $bg-white;
      font-weight: 600;
      font-size: $title1;
    }
  }
  .content {
    display: flex;
    flex-direction: row; 
    justify-content: center;
    gap: $gap-base;
    .items {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: $gap-small;
      .labels {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        color: $bg-white;
        .item {
          color: $bg-white;
          font-weight: 700;
          font-size: $font-size-small;
        }
      }
    }
  }

}
