@import "../../../../styles/app";

.root {
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem 1rem 0rem;
  height: 100%;
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px outset $white;
    .title {
      color: $secondary;
      font-size: $title3;
      font-weight: bold;
    }
    .btn {
      background-color: $primary;
      border-color: $primary;
      border-radius: 0.5rem;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin-bottom: 0rem;
    .cardInfo {
      display: flex;
      flex-direction: column;
      margin-bottom: 0rem;
      width: 100%;
      gap: $gap-base;
      > li {
        display: flex;
        flex-direction: row;
        gap: $gap-base;
        font-size: $font-size-base;
        justify-content: space-between;
        align-items: center;
        > span:first-child {
          display: flex;
          flex: 1;
          font-weight: bold;
          color: $secondary;
        }
        > span:last-child {
          display: flex;
          
        }
      }
    }
    .control {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      height: 100%;
      padding-top: 1rem;
    }
  }
}
