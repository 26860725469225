@import "../../../styles/app";

.header {
  // @extend %header-base;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr auto;
  grid-template-rows: auto;
  grid-template-areas: 
  'selectBuilding selectBuilding . . '
  'navigation navigation addDevice addDevice';
  // gap: 1rem;
  background-color: $bg-white;
  border-radius: 2.75rem 2.75rem 0rem 0rem;
  padding: $gap-base;
  row-gap: $gap-base;
  gap: 1rem;
  .selectBuilding {
    grid-area: selectBuilding;
  }
  .addDevice {
    grid-area: addDevice;
    .btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: $primary;
      border: 2px solid $primary;
      border-radius: 1.75rem;
      padding: 1rem 0.5rem 1rem 0.5rem;
      gap: $gap-small;  
    }
    .btn:hover {
      opacity: 0.8;
    }
  }
  .navigation {
    grid-area: navigation;
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
}