@import "../../../../../../../../styles/app";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $gap-base;
  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 2;
    gap: $gap-base;
    .description {
      display: flex;
      flex-direction: column;
      width: 100%;
      font-size: $font-size-base;
    }
    .form {
      display: flex;
      flex-direction: column;
      gap: $gap-base;
      width: 100%;
      .formGroup {
        display: flex;
        flex-direction: column;
        margin-bottom: 0rem !important;
        .label {
          margin-bottom: 0rem !important;
        }
        .input {
          border: none;
        }
      }
    }
  }
  
  .control {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: $gap-base;
    button {
      width: 25%;
    }
  }
}