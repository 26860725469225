@import "../../../../styles/app";


.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $bg-white;
  width: 100%;
  .title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size:  $title3;
    font-weight: bold;
    color: $secondary;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: $gap-base;
  .formGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 0rem !important;
    .label {
      margin-bottom: 0rem !important;
    }
    .input {
      color: $black;
      background-color: $light-gray;
      border: 1px solid $light-gray;
    }
  }
}

.control {
  display: flex;
  justify-content: center;
  > button {
    width: 25%;
  }
}


