@import "../../styles/app";
.root {
  display: flex;
  justify-content: center;
  font-size: $font-size-small;
  @media (min-width: 200px) and (max-width: 480px) {
    display: none;
  }
  @media (min-width: 481px) and (max-width: 768px) {
  }
  @media (min-width: 769px) and (max-width: 992px) {
  }
  @media (min-width: 993px) and (max-width: 1200px) {
  }
  @media (min-width: 1201px) and (max-width: 1400px) {
  }
  @media (min-width: 1401px) {
  }
}
