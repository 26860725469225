@import "../../../../../../styles/app";

.message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem;
  font-weight: bold;
  z-index: 2000;
}
