@import "../../styles/app";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $bg-white;
  border: solid 2px $gray;
  border-radius: $border-radius;
  color: $secondary;
  padding: 1rem;
  width: 100%;
  .header {
    display:  grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'title control aggregate';
      width: 100%;
    @media (min-width: 200px) and (max-width: 480px) {
      grid-template-columns: 1fr auto;
      grid-template-rows: auto;
      grid-template-areas:
        'title aggregate'
        'control control';
    }
    @media (min-width: 481px) and (max-width: 768px) {
      grid-template-columns: 1fr auto;
      grid-template-rows: auto;
      grid-template-areas:
        'title aggregate'
        'control control';
    }
    @media (min-width: 769px) and (max-width: 992px) {
      grid-template-columns: 1fr auto;
      grid-template-rows: auto;
      grid-template-areas:
        'title aggregate'
        'control control';
    }
    @media (min-width: 993px) and (max-width: 1200px) {
      grid-template-columns: 1fr 0.7fr 1fr;
    }
    @media (min-width: 1201px) and (max-width: 1400px) {
    }
    @media (min-width: 1401px) {
    }
    .title {
      grid-area: title;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: clamp($font-size-base, 1vw, $title4);
      font-weight: bold;
      color: $secondary;
    }
  }
  .content {
    grid-area: content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (min-width: 200px) and (max-width: 480px) {
      height: 25vh;
    }
    @media (min-width: 481px) and (max-width: 768px) {
      height: 25vh;
    }
    @media (min-width: 769px) and (max-width: 992px) {
      height: 30vh;
    }
    @media (min-width: 993px) and (max-width: 1200px) {
      height: 30vh;
    }
    @media (min-width: 1201px) and (max-width: 1400px) {
      height: 40vh;
    }
    @media (min-width: 1401px) {
      height: 40vh;
    }
  }
}
