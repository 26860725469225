@import '../../styles/app';

.progressStep {
  
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: relative;
  width: 100%;
  height: 3rem;
  .line {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border: 1px solid $primary;
    width: calc(100% - 6rem);
    top: calc(50% - 2px);
    left: calc(3rem);
    z-index: -5;
  }

  .currentProgress {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .circle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: $border-radius;
      background: $bg_app;
      border: 2px solid $white;
      > span {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $primary;
        font-size: $font-size-base;
        font-weight: 120;
      }
    }
    .active {
      background: $white;
      border: 2px solid $primary;
      > span {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $primary;
        font-size: $font-size-base;
        font-weight: bold;
      }
    }
    .pass {
      background: $primary;
      border: 2px solid $primary;
      > span {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        font-size: $font-size-base;
        font-weight: bold;
      }
    }
  }
}