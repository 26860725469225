@import '../../../../styles/app.scss';

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $bg-white;
  width: 100%;
  .title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: $title3;
    font-weight: bold;
    color: $secondary;
  }
}
.content {
  display: flex;
  flex-direction: column;
  gap: $gap-high;
  .recap {
    background-color: $gray-200;
    padding: 1.5rem;
    border-radius: $border-radius;
    gap: $gap-small;
    .location {
      display: flex;
      flex-direction: row;
      gap: $gap-small;
    }
  }
}
