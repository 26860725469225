@import "../../../../../../styles/app";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: $black;
  .dual_list_box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: $black;

    @media (min-width: 350px) and (max-width: 480px) {
      flex-direction: column;
      gap: 0.5rem;
    }
    @media (min-width: 481px) and (max-width: 768px) {
      flex-direction: column;
      gap: 0.5rem;
    }
    @media (min-width: 769px) and (max-width: 992px) {
      flex-direction: column;
      gap: 0.5rem;
    }
    @media (min-width: 993px) and (max-width: 1200px) {
      flex-direction: column;
      gap: 0.5rem;
    }
    @media (min-width: 1201px) and (max-width: 1400px) {
    }
    @media (min-width: 1401px) {
    }
    .list_option {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 15rem;
      border-radius: 1rem 0rem 0rem 1rem;
      background-color: $primary;
      box-shadow: $shadow;
      color: $white;
      padding: 1rem 0.1rem 0.2rem 0.2rem;
      @media (min-width: 350px) and (max-width: 480px) {
        width: 100%;
        padding:1rem 0.2rem 0.2rem 0.2rem ;
        border-radius: 1rem;
        height: 10rem;
      }
      @media (min-width: 481px) and (max-width: 768px) {
        width: 100%;
        padding:1rem 0.2rem 0.2rem 0.2rem ;
        border-radius: 1rem;
        height: 10rem;
      }
      @media (min-width: 769px) and (max-width: 992px) {
        width: 100%;
        padding:1rem 0.2rem 0.2rem 0.2rem ;
        border-radius: 1rem;
        height: 10rem;
      }
      @media (min-width: 993px) and (max-width: 1200px) {
        width: 100%;
        padding:1rem 0.2rem 0.2rem 0.2rem ;
        border-radius: 1rem;
        height: 10rem;
      }
      .label {
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .input {
        padding: 0.5rem;
        background: $bg-white;
        box-shadow: $shadow;
        border: none;
        outline: 0px;
        height: 100%;
        color: $secondary;
        overflow-y: auto;
        border-radius: 0rem 0rem 0rem 1rem;
        @media (min-width: 350px) and (max-width: 480px) {
          border-radius: 0rem 0rem 0.75rem 0.75rem;
        }
        @media (min-width: 481px) and (max-width: 768px) {
          border-radius: 0rem 0rem 0.75rem 0.75rem;
        }
        @media (min-width: 769px) and (max-width: 992px) {
          border-radius: 0rem 0rem 0.75rem 0.75rem;
        }
        @media (min-width: 993px) and (max-width: 1200px) {
          border-radius: 0rem 0rem 0.75rem 0.75rem;
        }
        @media (min-width: 1201px) and (max-width: 1400px) {
        }
        @media (min-width: 1401px) {
        }
        .option {
          border-radius: 2rem;
        }
        .option:hover {
          background-color: $bg-app;
          color: $secondary;
          border-radius: 2rem;
        }
      }
      .notFound {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
        background: $bg-app;
        box-shadow: $shadow;
        border: none;
        outline: 0px;
        height: 100%;
        color: $secondary;
        font-weight: 700;
        overflow-y: auto;
        border-radius: 0rem 0rem 0rem 1rem;
        @media (min-width: 350px) and (max-width: 480px) {
          border-radius: 0rem 0rem 0.75rem 0.75rem;
        }
        @media (min-width: 481px) and (max-width: 768px) {
          border-radius: 0rem 0rem 0.75rem 0.75rem;
        }
        @media (min-width: 769px) and (max-width: 992px) {
          border-radius: 0rem 0rem 0.75rem 0.75rem;
        }
        @media (min-width: 993px) and (max-width: 1200px) {
          border-radius: 0rem 0rem 0.75rem 0.75rem;
        }
        @media (min-width: 1201px) and (max-width: 1400px) {
        }
        @media (min-width: 1401px) {
        }
      }
    }
    .control_btn {
      display: flex;
      position: absolute;
      bottom: 10rem;
      flex-direction: column;
      @media (min-width: 350px) and (max-width: 480px) {
        position: relative;
        bottom: auto;
      }
      @media (min-width: 481px) and (max-width: 768px) {
        position: relative;
        bottom: auto;
      }
      @media (min-width: 769px) and (max-width: 992px) {
        position: relative;
        bottom: auto;
      }
      @media (min-width: 993px) and (max-width: 1200px) {
        position: relative;
        bottom: auto;
      }
      > button {
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid $primary;
        background-color: $bg-white;
        @media (min-width: 350px) and (max-width: 480px) {
          rotate: 90deg;
        }
        @media (min-width: 481px) and (max-width: 768px) {
          rotate: 90deg;
        }
        @media (min-width: 769px) and (max-width: 992px) {
          rotate: 90deg;
        }
        @media (min-width: 993px) and (max-width: 1200px) {
          rotate: 90deg;
        }
      }
    }
    .list_selected {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 15rem;
      border-radius: 0rem 1rem 1rem 0rem;
      background-color: $primary;
      box-shadow: $shadow;
      color: $white;
      padding: 1rem 0.2rem 0.2rem 0.1rem;
      @media (min-width: 350px) and (max-width: 480px) {
        width: 100%;
        padding:1rem 0.2rem 0.2rem 0.2rem ;
        height: 10rem;
        border-radius: 1rem;
      }
      @media (min-width: 481px) and (max-width: 768px) {
        width: 100%;
        padding:1rem 0.2rem 0.2rem 0.2rem;
        border-radius: 1rem;
        height: 10rem;
      }
      @media (min-width: 769px) and (max-width: 992px) {
        width: 100%;
        padding:1rem 0.2rem 0.2rem 0.2rem;
        border-radius: 1rem;
        height: 10rem;
      }
      @media (min-width: 993px) and (max-width: 1200px) {
        width: 100%;
        padding:1rem 0.2rem 0.2rem 0.2rem ;
        border-radius: 1rem;
        height: 10rem;
      }
      @media (min-width: 1201px) and (max-width: 1400px) {
      }
      @media (min-width: 1401px) {
      }
      .label {
        font-weight: bold;
        display: flex;
        justify-content: center;
      }
      .input {
        padding: 0.5rem;
        background: $bg-white;
        box-shadow: $shadow;
        border: none;
        outline: 0px;
        height: 100%;
        color: $secondary;
        overflow-y: auto;
        border-radius: 0rem 0rem 1rem 0rem;
        @media (min-width: 350px) and (max-width: 480px) {
          border-radius: 0rem 0rem 0.75rem 0.75rem;
        }
        @media (min-width: 481px) and (max-width: 768px) {
          border-radius: 0rem 0rem 0.75rem 0.75rem;
        }
        @media (min-width: 769px) and (max-width: 992px) {
          border-radius: 0rem 0rem 0.75rem 0.75rem;
        }
        @media (min-width: 993px) and (max-width: 1200px) {
          border-radius: 0rem 0rem 0.75rem 0.75rem;
        }
      }
      .option {
          border-radius: 2rem;
      }
      .option:hover {
        background-color: $bg-app;
        color: $secondary;
        border-radius: 2rem;
      }
    }
  }
  .error_box {
    .error_max {
      color: $danger;
    }
  }
}
