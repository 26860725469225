@import "../../../../styles/app";

.root {
  display: flex;
  flex-direction: column;
  height: calc(74vh - 1rem);
  overflow: hidden;
  background-color: $bg-white;
  border-radius: 2rem;
  @media (min-width: 200px) and (max-width: 480px) {
    height: calc(100vh - 5rem);
    gap: 0rem;
    border-radius: 0rem;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    height: calc(100vh - 5rem);
    gap: 0rem;
    border-radius: 0rem;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    border-radius: 0 0 2rem 2rem;
  }
  @media (min-width: 993px) and (max-width: 1200px) {
    border-radius: 0 0 2rem 2rem;
  }
  @media (min-width: 1201px) and (max-width: 1400px) {
    border-radius: 0 0 2rem 2rem;
  }
  @media (min-width: 1401px) {
    border-radius: 0 0 2rem 2rem;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem 0rem 1.5rem;
    @media (min-width: 200px) and (max-width: 480px) {
      padding-top: 0rem;
      gap: $gap-small;
    }
    @media (min-width: 481px) and (max-width: 768px) {
      padding-top: 0rem;
      gap: $gap-small;
    }
    @media (min-width: 769px) and (max-width: 992px) {
      gap: $gap-small;
    }
    @media (min-width: 993px) and (max-width: 1200px) {
    }
    @media (min-width: 1201px) and (max-width: 1400px) {
    }
    @media (min-width: 1401px) {
    }
    .title {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      color: $primary ;
      font-size: clamp($font-size-base, 3vw, $title4);
      font-weight: bold;
      gap: $gap-small;
      .period {
        color: $gray;
      }
      @media (min-width: 200px) and (max-width: 480px) {
        flex-direction: column;
        gap: 0rem;
        .period {
          font-size: $font-size-small;
          color: $gray;
        }
      }
      @media (min-width: 481px) and (max-width: 768px) {
        flex-direction: column;
        gap: 0rem;
        .period {
          font-size: $font-size-small;
          color: $gray;
        }
      }
      @media (min-width: 769px) and (max-width: 992px) {
        flex-direction: column;
        gap: 0rem;
        .period {
          font-size: $font-size-small;
          color: $gray;
        }
      }
      @media (min-width: 993px) and (max-width: 1200px) {
      }
      @media (min-width: 1201px) and (max-width: 1400px) {
      }
      @media (min-width: 1401px) {
      }
    }
    .control {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: $gap-base;
    flex-grow: 1;
    overflow-y: auto;
    padding: 1rem;
    scrollbar-width: none;
    
    .cardChart {
      display: flex;
    }

    .cardStatSummary {
      display: grid;
      grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
      column-gap: $gap-small;
      row-gap: $gap-small;
      padding: 0.5rem;
      background-color: $bg_app;
      border-radius: 1.75rem;
      @media (min-width: 200px) and (max-width: 480px) {
        grid-template-columns: 1fr;
        border-radius: 0rem;
      }
      @media (min-width: 481px) and (max-width: 768px) {
        grid-template-columns: 1fr;
        border-radius: 0rem;
      }
      @media (min-width: 769px) and (max-width: 992px) {
        grid-template-columns: 1fr;
      }
      @media (min-width: 993px) and (max-width: 1200px) {
        grid-template-columns: 0.5fr 0.5fr;
      }
      @media (min-width: 1201px) and (max-width: 1400px) {
        grid-template-columns: 0.5fr 0.5fr;
      }
      @media (min-width: 1401px) {
        grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
      }
    }

    .noBuilding {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $secondary;
      font-size: $title4;
      font-weight: bold;
      width: 100%;
      height: 100%;    
    }
  }
}

