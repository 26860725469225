@import "../../styles/app";

.root {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  padding-top: 1rem;
  row-gap: $gap-base;
  border: solid 2px $light-gray;
  border-radius: $border-radius;
  width: 100%;
  height: 100%;
  .graphic {
    color: $secondary;
    border-radius: $border-radius;
    background :$bg-white;
    padding: 1rem;
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: $title4;
        font-weight: bold;
        color: $secondary;
        flex: 1;
      }
      .control {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 2;
        .controlType {
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 100%;
          .btn {
            background-color: $secondary;
            width: 10%;
            padding: 0.2rem;
            &:hover {
              color: $white;
              font-weight: bold;
            }
            &.active {
              font-weight: bold;
              background-color: $primary;
            }
          }
        }
        .currentDate {
          display: flex;
          justify-content: center;
          color: $secondary;
          font-weight: bold;
          padding: 0.25rem;
        }
      }
      .aggregate {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: $title1;
        font-weight: bold;
        flex: 1;
      }
    }
    .content {
      height: 280px;
      .chart {
        width: 100%;
        height: 100%;
      }
    }
  }


  .noData {
    color: $black;
    border-radius: $border-radius;
    padding: 1rem;
    display: flex;
    justify-content: center;
    font-size: $title4;
    background-color: $white;
    margin-bottom: 1rem;
    @media (min-width: 200px) and (max-width: 1200px) {
      font-size: $font-size-base;
    }
    .message {
      text-align: center;
      width: 100%;
      @media (min-width: 200px) and (max-width: 1200px) {
        width: 60%;
      .noData {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90%;
        @media (min-width: 200px) and (max-width: 1200px) {
          font-size: $font-size-base;
        }
        .message {
          text-align: center;
          width: 100%;
          @media (min-width: 200px) and (max-width: 1200px) {
            width: 60%;
          }
        }
      }
    }
  }
  }


}
