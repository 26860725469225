@import '../../styles/app';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5%;
  width: 100%;
  height: 100%;
  .errorCode {
    margin: 1.6rem;
    font-size: 8rem;
    font-weight: 400;
    color: $black;
  }
  .errorInfo {
    font-size: $title1;
    color: $black;
  }
}







