@import "../../../../styles/app";

.form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 40%;
  padding: 1rem;
  gap: $gap-base;
  .formGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 0rem !important;
    width: 100%;
    .label {
      color: $secondary;
      font-weight: bold;
      margin-bottom: 0rem !important;
    }
    .input {
      width: 100%;
      background-color: $light-gray;
    }
    
  }
}
