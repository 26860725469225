/**            UTILS              **/

.circle {
  display: inline-block;
  width: #{$line-height-base}em;
  height: #{$line-height-base}em;
  line-height: #{$line-height-base}em;
  text-align: center;
}

.circle {
  @include border-radius(50%);
}

.collapse{
  width: 100%;
}