.custom-calendar.rmdp-wrapper,
.rmdp-container .custom-calendar.ep-arrow::after,
/* Style pour le survol complet avec fond, et non seulement la bordure */
.rmdp-calendar .rmdp-day-picker .rmdp-week .rmdp-day:hover .sd,

/* .rmdp-calendar .rmdp-day-picker .rmdp-week .rmdp-range */
.rmdp-calendar .rmdp-day-picker .rmdp-week .rmdp-day.rmdp-range
.rmdp-calendar .rmdp-day-picker .rmdp-week .rmdp-day.rmdp-range-hover .rmdp-day.rmdp-range-hover.start .sd, 
.rmdp-calendar .rmdp-day-picker .rmdp-week .rmdp-day.rmdp-range-hover .sd, 
.rmdp-calendar .rmdp-day-picker .rmdp-week .rmdp-day.rmdp-range-hover .rmdp-day.rmdp-range-hover.end .sd, 

.rmdp-calendar .rmdp-month:hover, 
.rmdp-calendar .rmdp-year:hover  {
  background-color: #3192D31A !important; /* Couleur de survol */
  border: none !important; /* Enlève toute bordure par défaut si nécessaire */
  color: #000000 !important;
}

/* Pour la sélection, on s'assure que toute la cellule est remplie */
.rmdp-calendar .rmdp-day-picker .rmdp-week .rmdp-day.rmdp-selected .sd, 

.rmdp-calendar .rmdp-day-picker .rmdp-week .rmdp-day.rmdp-range.start
.rmdp-calendar .rmdp-day-picker .rmdp-week .rmdp-day.rmdp-range.start .sd, 
.rmdp-calendar .rmdp-day-picker .rmdp-week .rmdp-day.rmdp-range,
.rmdp-calendar .rmdp-day-picker .rmdp-week .rmdp-day.rmdp-range .sd,
.rmdp-calendar .rmdp-day-picker .rmdp-week .rmdp-day.rmdp-range.end 
.rmdp-calendar .rmdp-day-picker .rmdp-week .rmdp-day.rmdp-range.end .sd, 

.rmdp-calendar .rmdp-month.rmdp-selected, 
.rmdp-calendar .rmdp-year.rmdp-selected {
  background-color: #3192D3 !important; /* Couleur de sélection */
  color: #ffffff !important; /* Texte en blanc */
  border: none !important; /* Enlève les bordures si existantes */
}

