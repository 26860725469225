@import "../../../../../../../../styles/app";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $gap-base;
  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 2;
    gap: $gap-base;
    .description {
      display: flex;
      flex-direction: column;
      width: 100%;
      font-size: $font-size-base;
      gap: 1rem;
      .descriptionParts {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .control {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: $gap-base;
    button {
      width: 25%;
    }
  }
}