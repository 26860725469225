@import "../../../styles/app";

.root {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 70%;
  margin: auto;
  margin-top: 4vh;
  align-items: stretch;
  @media (min-width: 200px) and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 0vh;
    height: 100vh;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 0vh;
  }
  @media (min-width: 993px) and (max-width: 1200px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin-top: 0vh;
  }
  @media (min-width: 1201px) and (max-width: 1400px) {
    width: 60%;
  }
  @media (min-width: 1401px) {
    width: 50%;
  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    @media (min-width: 200px) and (max-width: 480px) {
      display: none;
    }
    @media (min-width: 481px) and (max-width: 768px) {
      width: 40%;
    }
    @media (min-width: 769px) and (max-width: 992px) {
      width: 30%;
    }
    @media (min-width: 993px) and (max-width: 1200px) {
      width: 30%;
    }
    @media (min-width: 1201px) and (max-width: 1400px) {
    }
    @media (min-width: 1401px) {
    }
    .img {
      width: 100%;
      height: 100%;
      fill: $primary;
    }
  }
  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    @media (min-width: 200px) and (max-width: 768px) {
      width: 100%;
    }
    @media (min-width: 769px) and (max-width: 992px) {
    }
    @media (min-width: 993px) and (max-width: 1200px) {
    }
    @media (min-width: 1201px) and (max-width: 1400px) {
    }
    @media (min-width: 1401px) {
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: $border-radius;
      background-color: $bg-white;
      padding: 1.5rem 1.5rem 1.5rem 1.5rem;
      width: 25rem;
      height: 100%;
      @media (min-width: 200px) and (max-width: 480px) {
        border-radius: 0rem;
        padding: 1.5rem 1.5rem 1.5rem 1.5rem;
        width: 100%;
        gap: $gap-high;
      }
      @media (min-width: 481px) and (max-width: 768px) {
        width: 80%;
      }
      @media (min-width: 769px) and (max-width: 992px) {
        width: 50%;
      }
      @media (min-width: 993px) and (max-width: 1200px) {
        width: 60%;
      }
      @media (min-width: 1201px) and (max-width: 1400px) {
        width: 100%;
      }
      @media (min-width: 1401px) {
      }

      header {
        font-size: $title1;
        font-weight: 300;
        line-height: 120%;
      }

      .form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: $font-size-small;
        .formGroup {
          display: flex;
          flex-direction: column;
          width: 100%;
          @media (min-width: 200px) and (max-width: 768px) {
            width: 100%;
            padding-left: 1rem;
            padding-right: 1rem;
          }
          @media (min-width: 769px) and (max-width: 992px) {
          }
          @media (min-width: 993px) and (max-width: 1200px) {
          }
          @media (min-width: 1201px) and (max-width: 1400px) {
          }
          @media (min-width: 1401px) {
          }
        }
        // .error_message {
        //   background-color: transparent;
        //   color: $danger;
        //   font-weight: bold;
        //   padding: 0;
        //   border-radius: 0;
        // }
        .password_checklist{
          display: flex;
          width: 100%;
        }
        .passwordStrengthBar {
          display: flex;
          flex-direction: column-reverse;
          width: 100%;
          > div div {
            height: 4px !important;
          }
          > div div:nth-child(even) {
            width: 0px !important;
          }
          > p {
            display: flex;
            justify-content: flex-start;
            color: black !important;
            font-weight: bold;
          }
        }
        .control {
          padding-top: 0.5rem;
          display: flex;
          justify-content: flex-end;
          width: 100%;
          @media (min-width: 200px) and (max-width: 768px) {
            width: 100%;
            padding-left: 1rem;
            padding-right: 1rem;
          }
          @media (min-width: 769px) and (max-width: 992px) {
          }
          @media (min-width: 993px) and (max-width: 1200px) {
          }
          @media (min-width: 1201px) and (max-width: 1400px) {
          }
          @media (min-width: 1401px) {
          }
        }
      }
      

      .separator {
        display: flex;
        align-items: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        width: 100%; 
        > span {
          border-top: 2px solid $primary;
          width: 100%
        }
      }

      footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 0rem;
        gap: $gap-high;
        width: 100%;
        .label {
          font-size: $title3;
          line-height: 120%;
          text-align: center;
        }
      }
    }
  }
}