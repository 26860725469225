@import '../../../../styles/app.scss';

.root {
  display: flex;
  flex-direction: column;
  gap: $gap-base;
  padding: 1rem 0.5rem;
  background-color: $bg-white;
  border-radius: $border-radius;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0rem 0.5rem;
    .label {
      font-size: $font-size-base;
      font-weight: 600;
      color: $secondary;
    }
    .switch{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: $gap-small;
      .button{
        padding: 0.2rem;
        cursor: pointer;
        border-radius: 2rem;
        &:hover{
          background-color: $bg-app;
        }
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: $gap-small;
    .ranking {
      background-color: $bg-white;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-radius: 2rem;
      padding: 0rem 0.5rem;
      .label {
        font-size: $font-size-base;
        font-weight: 400;
        color: $secondary;
      }
    }
    .rankingGray {
      background-color: $bg-app;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-radius: 2rem;
      padding: 0.25rem 0.5rem;
      .label {
        font-size: $font-size-base;
        font-weight: 400;
        color: $secondary;
      }
    }
  }
}
