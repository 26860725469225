@import "../../styles/app";

.popupContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $background-modal;
  z-index: 2000;
  transition: transform 0.3s linear; 

  .popupCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 40%;
    min-height: 20%;
    background-color: $bg-white;
    box-shadow: 0 4px 8px $background-modal;
    overflow: hidden;
    border-radius: $border-radius;
    border: 2px solid $primary;
    animation: slideDown 0.3s linear;
    z-index: 1000;
    transform: translateY(-200%);
    transition: transform 0.3s linear;
    padding: 1.5rem;
    gap: $gap-base;
    &.show {
      transform: translateY(0%);
    }

    .header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      .title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size:$title4;
        font-weight: bold;
        color: $secondary;
      }
      .btnClose {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: calc(30% + 1rem);
        width: 2rem;
        height: 2rem;
        border-radius: 1rem;
        padding: 0.4rem;
        background-color: $white;
        cursor: pointer;
        .icon {
          fill: $secondary;
        }
      }
    }
    .header-centered {
      justify-content: center;
    }

    .content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .control {
      display: flex;
      justify-content: center;
      gap: $gap-base;
      width: 100%;
      > button {
        width: 25%;
      }
    }
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-200%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}