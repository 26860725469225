@import '../../../../../../styles/app.scss';

.root {
  display: flex;
  flex-direction: column;
  border-radius: 1.5rem;
  background-color: $bg-app;
  border: 2px solid $bg-app;
  width: 100%;
  padding: 1.5rem;
  gap: $gap-high;
  transition: border-color 0.2s ease;
  @media (min-width: 200px) and (max-width: 480px) {
      
  }
  @media (min-width: 481px) and (max-width: 768px) {
    width: 100%;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    width: 100%;
  }
  @media (min-width: 993px) and (max-width: 1200px) {
    width: 100%;
  }
  @media (min-width: 1201px) and (max-width: 1400px) {
    width: 45%;
  }
  @media (min-width: 1401px) and (max-width:1488px) {
    width: 40%;
  }
  &:hover {
    border: 2px solid $primary;
    cursor: pointer;
    .header .arrowIcon {
      opacity: 1;
      transform: translateX(0);
    }
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .title {
      font-size: $title3;
      color: $secondary;
    }
    .control {
      display: flex;
      flex-direction: row;
      .unlink {
        display: flex;
        padding: 0.5rem;
        align-items: center;
        gap: $gap-small;
        color: $danger;
        border-radius: $border-radius;
        &:hover {
          background-color: $red-transparent;
          cursor: pointer;
        }
      }
    }
    .arrowIcon {
      display: flex;
      opacity: 0; 
      transform: translateX(-120px); 
      transition: opacity 0.2s ease, transform 0.5s ease;
    }
  }
  .infos {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .info {
      display: flex;
      flex-direction: row;
      width: 100%;
      flex-wrap: wrap;
      gap: $gap-small;
      > span {
        font-weight: 700;
      }
    }
  }
}
