@import '../../styles/app';

.progressStep {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  height: 3rem;

  .currentProgress {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .square {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 8px;
      background: $secondary;
      border: 1px solid $white;
      > span {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        font-size: $font-size-base;
        font-weight: normal;
      }
    }
    .active {
      background: $white;
      border: 1px solid $secondary;
      transform: rotate(45deg);
      animation: logo_animate 0.5s ease-in;
      > span {
        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotate(-45deg);
        color: $secondary;
        font-size: $font-size-base;
        font-weight: bold;
      }
    }
    @keyframes logo_animate {
      0% {
        background: $secondary;
        transform: rotate(0deg);
        scale: 1;
      }
      50% {
        scale: 1.2;
      }
      100% {
        transform: rotate(45deg);
        background: $white;
        scale: 1;
      }
    }
  }
}