@import "../../../../../../styles/app";

.root {
  border-radius: 1.5rem 1.5rem 1.5rem 1.5rem;
  border: 2px solid $bg-app;
  background-color: $bg-app;
  border-radius: 1.5rem;
  padding: 1rem 1.5rem;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 1.5rem 1.5rem 0 0;
    .title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: $title3;
      font-weight: 300;
      color: $secondary;
    }
   .control {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      .delete {
        border-radius: 2rem;
        padding: 0.5rem;
        &:hover {
          background-color: $danger-transparent;
        }
      }
      .modify {
        border-radius: 2rem;
        padding: 0.5rem;
        &:hover {
          background-color: $bg-white;
        }
      }
    }
  }
  
  .content {
    display: flex;
    flex-direction: row;
    margin-bottom: 0rem;
    width: 100%;
    border-radius: 0 0 1.5rem 1.5rem;
    .info {
      display: flex;
      flex-direction: column;
      width: 100%;
      .item {
        display: flex;
        flex-direction: row;
        gap: $gap-base;
        font-size: $font-size-base;
        > span:first-child {
          display: flex;
          flex: 1;
          font-weight: bold;
          color: $secondary;
        }
        > span:last-child {
          display: flex; 
        }
      }
    }
  }
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: $gap-base;
    padding-bottom: 1rem;
    button {
      width: 25%;
    }
  }
}
