@import '../../../styles/app';

.root {
  display: flex;
  flex-direction: row;
  position: relative;
  border-radius: 1.75rem;
  border: solid 2px $primary;
  background-color: transparent;
  width: 28%;
  @media (min-width: 200px) and (max-width: 480px) {
    width: 100%;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    width: 100%;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    width: 100%;
  }
  @media (min-width: 993px) and (max-width: 1200px) {
    width: 100%;
  }
  @media (min-width: 1201px) and (max-width: 1400px) {
  }
  @media (min-width: 1401px) {
  }
  .controller {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: transparent;
    gap: $gap-base;
    padding: 0.4rem 1rem 0.4rem 1rem;
    .iconCalendar {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      @media (min-width: 200px) and (max-width: 480px) {
        display: none;
      }
      @media (min-width: 481px) and (max-width: 768px) {
      }
      @media (min-width: 769px) and (max-width: 992px) {
      }
      @media (min-width: 993px) and (max-width: 1200px) {
      }
      @media (min-width: 1201px) and (max-width: 1400px) {
      }
      @media (min-width: 1401px) {
      }
      > span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3rem;
        height: 3rem;
        border-radius: 2rem;
        background-color: $bg-app;
      }  
    }
    .navPeriod {
      display: flex;
      justify-content: space-around;
      align-items: center;
      background-color: transparent;
      flex: 1;
      gap: $gap-small;
      @media (min-width: 200px) and (max-width: 480px) {
        justify-content: center;
        background-color: transparent;
        padding: 0.8rem 1rem 0.8rem 1rem;
      }
      @media (min-width: 481px) and (max-width: 768px) {
      }
      @media (min-width: 769px) and (max-width: 992px) {
      }
      @media (min-width: 993px) and (max-width: 1200px) {
      }
      @media (min-width: 1201px) and (max-width: 1400px) {
      }
      @media (min-width: 1401px) {
      }
      > span {
        display: flex;
        justify-content: center;
        align-items: center;
        @media (min-width: 200px) and (max-width: 480px) {
          display: none;
        }
        @media (min-width: 481px) and (max-width: 768px) {
        }
        @media (min-width: 769px) and (max-width: 992px) {
        }
        @media (min-width: 993px) and (max-width: 1200px) {
        }
        @media (min-width: 1201px) and (max-width: 1400px) {
        }
        @media (min-width: 1401px) {
        }
      }

      .currentPeriod { 
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        background-color: transparent;
        cursor: pointer;
        flex: 5;
      } 
    } 
  }


  .modalCalendar{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: 4rem;
    background-color: $bg-white;
    border-radius: 1.75rem;
    box-shadow: $shadow;
    border: solid 2px $primary;
    z-index: 100;
    @media (min-width: 200px) and (max-width: 480px) {
    }
    @media (min-width: 481px) and (max-width: 768px) {
    }
    @media (min-width: 769px) and (max-width: 992px) {
    }
    @media (min-width: 993px) and (max-width: 1200px) {
    }
    @media (min-width: 1201px) and (max-width: 1400px) {
      width: 100%;
    }
    @media (min-width: 1401px) {
    }
    .containerCalendar {
      display:  grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      grid-template-areas:
        'btn .'
        'calendar shortcut'
        'confirm .';
      column-gap: $gap-small;
      row-gap: $gap-small;
      padding: 1rem; 
      @media (min-width: 200px) and (max-width: 480px) {
        grid-template-columns: auto 1fr auto;
        grid-template-areas:
        '. btn .'
        '. calendar .'
        '. confirm .';
      }
      @media (min-width: 481px) and (max-width: 768px) {
      }
      @media (min-width: 769px) and (max-width: 992px) {
      }
      @media (min-width: 993px) and (max-width: 1200px) {
      }
      @media (min-width: 1201px) and (max-width: 1400px) {
        grid-template-columns: auto 1fr auto;
        grid-template-areas:
        '. btn .'
        '. calendar .'
        '. confirm .';
      }
      @media (min-width: 1401px) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
        'btn .'
        'calendar shortcut'
        'confirm .';
      }
      > button {
        grid-area: btn;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      } 
      .controlShortcut {
        grid-area: shortcut;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: $gap-small;
        @media (min-width: 200px) and (max-width: 480px) {
          display: none;
        }
        @media (min-width: 481px) and (max-width: 768px) {
        }
        @media (min-width: 769px) and (max-width: 992px) {
        }
        @media (min-width: 993px) and (max-width: 1200px) {
        }
        @media (min-width: 1201px) and (max-width: 1400px) {
          display: none;
        }
        @media (min-width: 1401px) {
        }
      }
      .calendar {
        grid-area: calendar;
        display: flex;
        justify-content: center;
        width: 100% !important;
        height: 100%;
        box-shadow: none;
        border: solid 2px $light-gray;
        border-radius: $border-radius;
      }
      .confirmCalendar{
        grid-area: confirm; 
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: $gap-base;
        > button {
          width: 100%;
        }
      }
    }
  }
} 






