@import '../../../../styles/app.scss';

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $bg-white;
  width: 100%;
  .title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: $title3;
    font-weight: bold;
    color: $secondary;
  }
}
.content {
  display: flex;
  flex-direction: column;
  gap: $gap-base;
  max-height: 60vh;
  .message {
    color: $secondary;
    font-size: $font-size-base;
  }
  .solution{
    display: flex;
    flex-direction: column;
    gap: $gap-small;
    .title {
      font-size: $title3;
      color: $secondary;
    }
    .advice {
      font-size: $font-size-base;
      color: $secondary;
    }
  }
}
