@import '../../../../../../styles/app.scss';

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $bg-white;
  width: 100%;
  .title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: $title3;
    font-weight: bold;
    color: $secondary;
  }
}
.devices {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  gap: $gap-high;
  .message {
    color: $secondary;
  }
  .device {
    display: flex;
    flex-direction: column;
    height: calc(40vh - 1rem);
    width: 100%;
    gap: $gap-high;
    overflow: scroll;
    scrollbar-width: none;
  }
}
.control {
  display: flex;
  justify-content: center;
  button {
    width: 25%;
  }
}
