@import "../../../../../../styles/app";

@import url("leaflet/dist/leaflet.css");

.leaflet-container {
  width: max-content;
  height: 100%;
}

.root {
  z-index: 2;
  width: max-content;
  width: 100%;
  height: 100%;
  border: 2px solid $secondary;
  border-radius: 1.5rem;
  @media (min-width: 200px) and (max-width: 480px) {
    height: 25vh;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    height: 30vh;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    height: 50vh;
  }
  @media (min-width: 993px) and (max-width: 1200px) {
    height: 50vh;
  }
  @media (min-width: 1201px) and (max-width: 1400px) {
    height: 100%;
  }
  @media (min-width: 1401px) {
    height: 100%;
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .title {
      color: $secondary;
      font-size: $title3;
      font-weight: bold;
    }
  }
  button {
    position: absolute;
    width: 20%;
    top: 85%;
    left: 40%;
    z-index: 1000;
  }
}