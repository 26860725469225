@import "../../styles/app";

.modalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $background-modal;
  z-index: 2000; 
  transition: transform 0.3s linear; 
  
  .modalCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 40%;
    min-height: 20%;
    background-color: $bg-white;
    box-shadow: 0 4px 8px $background-modal;
    overflow: hidden;
    border-radius: $border-radius;
    border: 2px solid $primary;
    animation: slideDown 0.3s linear;
    z-index: 1000;
    transform: translateY(-200%);
    transition: transform 0.3s linear;
    padding: 1.5rem;
    &.show {
      transform: translateY(0%);
    }
    
    .content {
      display: flex;
      flex-direction: column;
      gap: $gap-base;
    }

  }
}

@keyframes slideDown {
  from {
    transform: translateY(-200%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}