@import "../../../styles/app";

.nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  .title {
    display: flex;
    color: $secondary;
    font-size: $title1;
  }
  .controlSort {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 2rem;
    border: 2px solid $primary;
    width: 100%;
    .col {
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid $light-gray;
      flex: 0.5;
      padding: 1rem;
      cursor: pointer;
    }
    .col:last-child {
      cursor: default;
      border-right: none;
    }
  }
}

