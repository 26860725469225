@import '../../../../../styles/app.scss';
.root {
  display: flex;
  flex-direction: column;
  gap: $gap-base;
  .selectBuilding {
    display: flex;
    flex-direction: column;
    gap: $gap-small;
    .inputTitle {
      font-size: $font-size-base;
      font-weight: 700;
    }
    .formGroupBuilding {
      display: flex;
      flex-direction: row;
      position: relative;
      width: 100%;
      margin-bottom: 0rem !important;
      border-radius: 1.75rem;
      .inputGroupBuilding {
        height: 100%;
        border: none;
        outline: none;
        .input {
          display: flex;
          align-items: center;
          background-color: $bg-app;
          font-size: $font-size-base;
          box-shadow: none;
          outline: none;
          border: none;
          cursor: pointer;
          @media (min-width: 200px) and (max-width: 480px) {
            background-color: transparent;
          }
          @media (min-width: 481px) and (max-width: 768px) {
          }
          @media (min-width: 769px) and (max-width: 992px) {
          }
          @media (min-width: 993px) and (max-width: 1200px) {
          }
          @media (min-width: 1201px) and (max-width: 1400px) {
          }
          @media (min-width: 1401px) {
          }  
        }
      }
    }
  }
  .checkgroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: $gap-small;
    .label {
      width: 95%;
      margin: 0;
    }
    .checkBox {
      margin: 0;
      border: 2px solid $primary;
    }
    .checkBox:hover {
      cursor: pointer;
    }
  }
  .btnSteps {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .btn {
      background-color: $bg-white;
      border: 1px solid $primary;
      display: flex;
      justify-content: center;
      color: $primary;
      align-items: center;
      padding: 0.5rem 1rem 0.5rem 1rem;
      border-radius: $border-radius;
      gap: $gap-small;
      cursor: pointer;
    }
    .btnActive {
      background-color: $primary;
      border: 1px solid $primary;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $bg-white;
      padding: 0.5rem 1rem 0.5rem 1rem;
      border-radius: $border-radius;
      gap: $gap-small;
      cursor: pointer;
    }
    .btnDisable {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 1rem 0.5rem 1rem;
      border-radius: $border-radius;
      border: 1px solid $gray-500;
      color: $bg-white;
      gap: $gap-small;
      background-color: $gray-500;
      cursor:default;
    }
  }
}