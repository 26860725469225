@import '../../../../styles/app.scss';

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: $border-radius;
  background-color: $bg-white;
  padding: 1rem;
  .content {
    display: flex;
    flex-direction: column;
    gap: $gap-small;
    padding: 0rem 0rem 1rem 0rem;
    .title {
      color: $primary;
      font-weight: 600;
    }
    .description {
      color: $secondary;
    }
  }
  .button {
    .btn {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-color: $primary;
      border-radius: 2rem;
      background-color: $primary;
      color: $bg-white;
      width: 100%;
    }
  }
}
