@import "../../styles/app";

.root {
  @extend %page-base;
  .content {
    @extend %page-content;
    gap: 0rem;
    .report {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      border-left: 1px solid $light-gray;
      border-right: 1px solid $light-gray;
      .col {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0.5;
        overflow: hidden;
        @media (min-width: 200px) and (max-width: 480px) {
          .btn_fileopen, .btn_delete {
            display: none;
          }
        }
        @media (min-width: 481px) and (max-width: 768px) {
          .btn_fileopen, .btn_delete {
            display: none;
          }
        }
        @media (min-width: 769px) and (max-width: 992px) {
        }
        @media (min-width: 993px) and (max-width: 1200px) {
        }
        @media (min-width: 1201px) and (max-width: 1400px) {
        }
        @media (min-width: 1401px) {
        }
      }
      .col:last-child {
        gap: $gap-base;
      }
    }
    .grayBackground {
      background-color: $light-gray; // Remplacez $light-gray par la couleur de votre choix
    }
  }
}