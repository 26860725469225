@import '../../../../../styles/app.scss';

.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: $gap-small;
  .scenariosParam {
    display: flex;
    flex-direction: column;
    gap: $gap-small;
    padding: 0.5rem 0rem;
    .message {
      display: flex;
      flex-direction: row;
      gap: 0.62rem;
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .scenarioSteps {
      display: flex;
      flex-direction: column;
      gap: $gap-high;
      .title {
        font-weight: 400;
      }
      .selector {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $gap-small;
        .data {
          background-color: $bg-app;
          padding: 0.5rem;
          display: flex;
          flex-direction: column;
          gap: $gap-small;
          border-radius: 1.75rem;
          align-items: center;
          &.disable {
            width: 100%;
          }
          .title {
            display: flex;
            justify-content: center;
            color: $primary;
            font-weight: 700;
          }
          .buttons {
            display: flex;
            flex-direction: row;
            gap: $gap-small;
            flex-wrap: wrap;
            justify-content: center;
            .btn {
              display: flex;
              padding: 0.5rem;
              justify-content: center;
              align-items: center;
              gap: 0.625rem;
              background-color: $bg-white;
              color: $secondary;
              border: 2px solid $bg-white;
              &:hover {
                border: 2px solid $primary;
              }
              &.active {
                opacity: 1;
                border: 2px solid $primary;
                background-color: $bg-white;
                .item {
                  color: $primary;
                }
              }
              &.disabled {
                opacity: 1;
              }
            }
          }
          .inputs {
            display: flex;
            flex-direction: row;
            gap: $gap-small;
            .input {
              border-radius: $border-radius;
              background-color: $bg-white;
              border: transparent;
              border-right: none;
              outline: none;
              box-shadow: none;
              padding: 0.5rem;
              align-content: center;
            }
          }
          .input {
            border-radius: $border-radius;
            background-color: $bg-white;
            border: transparent;
            border-right: none;
            outline: none;
            box-shadow: none;
            padding: 0.5rem;
            align-content: center;
          }
        }
      }
    }
  }
  .btnSteps {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .btn {
      background-color: $bg-white;
      border: 1px solid $primary;
      display: flex;
      justify-content: center;
      color: $primary;
      align-items: center;
      padding: 0.5rem 1rem 0.5rem 1rem;
      border-radius: $border-radius;
      gap: $gap-small;
      cursor: pointer;
    }

    .btnActive {
      background-color: $primary;
      border: 1px solid $primary;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $bg-white;
      padding: 0.5rem 1rem 0.5rem 1rem;
      border-radius: $border-radius;
      gap: $gap-small;
      cursor: pointer;
    }
    .btnDisable {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 1rem 0.5rem 1rem;
      border-radius: $border-radius;
      border: 1px solid $gray-500;
      color: $bg-white;
      gap: $gap-small;
      background-color: $gray-500;
      cursor: default;
    }
  }
}
