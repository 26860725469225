@import '../../../../styles/app.scss';

.root {
  display: flex;
  width: 13.04169rem;
  height: 9.75rem;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: $gap-small;
  background-color: $bg-white;
  border-radius: $border-radius;
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $gap-small;
    flex: 1 0 0;
    align-self: stretch;
    .items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: $gap-base;
      .item {
        font-size: $title1;
        color: $secondary;
      }
    }
    .description {
      color: $secondary;
      text-align: right;
      font-size: $font-size-small;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
  }
}
