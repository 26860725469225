@import "../../../../styles/app";

.root {
  display: flex;
  flex-direction: column;
  height: calc(74vh - 1rem);
  overflow-y: hidden;
  background-color: $bg-white;
  border-radius: 2rem;
  border: 2px solid $light-gray;
  @media (min-width: 200px) and (max-width: 480px) {
    height: calc(100vh - 5rem);
    border-radius: 0rem;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    height: calc(100vh - 5rem);
    border-radius: 0rem;
  }
  @media (min-width: 769px) and (max-width: 992px) {
  }
  @media (min-width: 993px) and (max-width: 1200px) {
  }
  @media (min-width: 1201px) and (max-width: 1400px) {
  }
  @media (min-width: 1401px) {
  }
  .content {
    display: flex;
    flex-direction: column;
    background-color: $bg-white;
    padding: 1rem;
    gap: $gap-base;
    overflow-y: auto;
    scrollbar-width: none;
  }
}
