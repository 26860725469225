@import "../../../../styles/app";

.content {
  display: grid;
  grid-template-columns: 1.75fr 0.5fr;
  grid-template-rows: 1.25fr 0.5fr;
  grid-template-areas:
    'cardMap cardBuildingData'
    'cardMap cardBuildingData';
  background-color: $bg-white;
  padding: 1rem;
  column-gap: $gap-base;
  row-gap: $gap-base;
  overflow-y: auto;
  scrollbar-width: none;
  flex-grow: 1;
  @media (min-width: 200px) and (max-width: 480px) {
    grid-template-columns: 1fr;
    grid-template-areas:
    'cardMap'
    'cardBuildingData';
  }
  @media (min-width: 481px) and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-areas:
    'cardMap'
    'cardBuildingData';
  }
  @media (min-width: 769px) and (max-width: 992px) {
    grid-template-columns: 1fr;
    grid-template-areas:
    'cardMap'
    'cardBuildingData';
  }
  @media (min-width: 993px) and (max-width: 1200px) {
    grid-template-columns: 1fr;
    grid-template-areas:
    'cardMap'
    'cardBuildingData';
  }
  @media (min-width: 1201px) and (max-width: 1400px) {
    grid-template-columns: 1.5fr 0.5fr;
    grid-template-areas:
    'cardMap cardBuildingData'
    'cardMap cardBuildingData';
  }
  @media (min-width: 1401px) {
    grid-template-columns: 1.5fr 0.5fr;
    grid-template-areas:
    'cardMap cardBuildingData'
    'cardMap cardBuildingData';
  }
  .cardBuildingData {
    grid-area: cardBuildingData;
  }
  .cardMap {
    grid-area: cardMap;
  } 
}