@import '../../../../../../../../styles/app.scss';

.root {
  display: flex;
  flex-direction: column;
  gap: $gap-high;
  .createDevice{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: $gap-high;
    .message{
      color: $secondary;
    }
    .btn{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem;
        background-color: $primary;
        border-color: $primary ;
        gap: 0.5rem;
    }
  }
  .devices {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    gap: $gap-high;
    .message{
      color: $secondary;
    }
    .device{
      display: flex;
      flex-direction: column;
      height: calc(40vh - 1rem);
      width: 100%;
      gap: $gap-high;
      overflow: scroll;
      scrollbar-width: none;
    }
  }
}
