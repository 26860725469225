@import '../../../styles/app';

.controler {
  display: flex;
  border-radius: 1.75rem;
  border: solid 2px $primary;
  .formGroupBuilding {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 50%;
    margin-bottom: 0rem !important;
    border-radius: 1.75rem;
    background-color: transparent !important;
    .inputGroupBuilding {
      height: 100%;
      border: none;
      outline: none;
      background-color: transparent !important;
      .inputGroupText {
        background-color: transparent !important;
        border-radius: 1.75rem 0 0 1.75rem;
        border: none;
        outline: none;
        cursor: pointer;
        @media (min-width: 200px) and (max-width: 480px) {
          display: none;
        }
        @media (min-width: 481px) and (max-width: 768px) {
        }
        @media (min-width: 769px) and (max-width: 992px) {
        }
        @media (min-width: 993px) and (max-width: 1200px) {
        }
        @media (min-width: 1201px) and (max-width: 1400px) {
        }
        @media (min-width: 1401px) {
        }
        > span {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 3rem;
          height: 3rem;
          border-radius: 1.5rem;
          background-color: $bg-app;
        } 
      }
      .inputGroupText:hover {
        background-color: $bg-white;
      }
      .input {
        display: flex;
        align-items: center;
        background-color: transparent !important;
        font-size: $font-size-base;
        box-shadow: none;
        outline: none;
        border: none;
        cursor: pointer;
        @media (min-width: 200px) and (max-width: 480px) {
          background-color: transparent;
        }
        @media (min-width: 481px) and (max-width: 768px) {
        }
        @media (min-width: 769px) and (max-width: 992px) {
        }
        @media (min-width: 993px) and (max-width: 1200px) {
        }
        @media (min-width: 1201px) and (max-width: 1400px) {
        }
        @media (min-width: 1401px) {
        }
        .activeText{
          color: $primary;
          font-weight: bold;
          border-radius: 0rem;
        }
      }
    }
    .listGroup {
      border: 1px solid $light-gray;
      display: flex;
      position: absolute;
      background-color: $bg-white;
      z-index: 1;
      top: 4.1rem;
      left: 6%;
      border-radius: 1.75rem;
      width: 90%;
      .item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: $gap-small;
        padding-left: 1rem;
        padding-right: 1rem;
        width: 100%;
        .name {
          display: flex;
          justify-content: flex-start;
          background-color: transparent;
          border: none;
        }
      }
      .item:hover:first-child {
        border-radius: $border-radius $border-radius 0 0;
      }
      .item:hover:last-child {
        border-radius: 0 0 $border-radius $border-radius;
      }
      .item:hover {
        background-color: $light-gray;
      }
    }
  }
  .formGroupRoom {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 50%;
    margin-bottom: 0rem !important;
    border-radius: 1.75rem;
    .inputGroupRoom {
      border: none;
      outline: none;
      border-left: 2px solid $light-gray;
      background-color: transparent !important;
      @media (min-width: 200px) and (max-width: 480px) {
        height: 4rem;
      }
      @media (min-width: 481px) and (max-width: 768px) {
      }
      @media (min-width: 769px) and (max-width: 992px) {
      }
      @media (min-width: 993px) and (max-width: 1200px) {
      }
      @media (min-width: 1201px) and (max-width: 1400px) {
      }
      @media (min-width: 1401px) {
      }
      .inputGroupText {
        background-color: transparent !important;
        border-radius: 1.75rem 0 0 1.75rem;
        border: none;
        outline: none;
        cursor: pointer;
        @media (min-width: 200px) and (max-width: 480px) {
          display: none;
        }
        @media (min-width: 481px) and (max-width: 768px) {
        }
        @media (min-width: 769px) and (max-width: 992px) {
        }
        @media (min-width: 993px) and (max-width: 1200px) {
        }
        @media (min-width: 1201px) and (max-width: 1400px) {
        }
        @media (min-width: 1401px) {
        }
        > span {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 3rem;
          height: 3rem;
          border-radius: 2rem;
          background-color: $bg-app;
        } 
      }
      .inputGroupText:last-child {
        background-color: $bg-white;
        border-radius: 0 1.75rem 1.75rem 0;
        cursor: pointer;
        > span {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 3rem;
          height: 3rem;
          border-radius: 0rem;
          background-color: transparent;
        } 
      }
      .inputGroupText:hover {
        background-color: $bg-white;
      }
      .input {
        display: flex;
        align-items: center;
        background-color: transparent !important;
        font-size: $font-size-base;
        box-shadow: none;
        outline: none;
        border: none;
        cursor: pointer;
        height: 100%;
        @media (min-width: 200px) and (max-width: 480px) {
          background-color: transparent;
        }
        @media (min-width: 481px) and (max-width: 768px) {
        }
        @media (min-width: 769px) and (max-width: 992px) {
        }
        @media (min-width: 993px) and (max-width: 1200px) {
        }
        @media (min-width: 1201px) and (max-width: 1400px) {
        }
        @media (min-width: 1401px) {
        }
        .activeText{
          color: $primary;
          font-weight: bold;
          border-radius: 0rem;
        }
      }

    }
    .listGroup {
      border: 1px solid $light-gray;
      display: flex;
      position: absolute;
      background-color: $bg-white;
      z-index: 1;
      top: 4.1rem;
      left: 14%;
      border-radius: $border-radius;
      width: 80%;
      .item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: $gap-small;
        padding-left: 1rem;
        padding-right: 1rem;
        width: 100%;
        .name {
          display: flex;
          justify-content: flex-start;
          background-color: transparent;
          border: none;
        }
      }
      .item:first-child {
        border: 1px solid $bg-app;
      }
      .item:hover:first-child {
        border-radius: $border-radius $border-radius 0 0;
      }
      .item:hover:last-child {
        border-radius: 0 0 $border-radius $border-radius;
      }
      .item:hover {
        background-color: $light-gray;
      }
    }
  }
}
