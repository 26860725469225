@import "../../../styles/app";
.root {
 .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 1rem 0rem 1rem;
    @media (min-width: 200px) and (max-width: 480px) {
    }
    @media (min-width: 481px) and (max-width: 768px) {
    }
    @media (min-width: 769px) and (max-width: 992px) {
    }
    @media (min-width: 993px) and (max-width: 1200px) {
    }
    @media (min-width: 1201px) and (max-width: 1400px) {
    }
    @media (min-width: 1401px) {
    }
  } 
  .slideMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    background: radial-gradient(68.88% 68.88% at 5.57% 15.59%, #F6FBFF 0%, #AECDF0 100%);
    width: 100%;
    height: 100%;
    z-index: 1000;
    gap: $gap-small;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: $white;
    transform: translate(0, 0);
    &.show {
      animation: slideToLeft 0.3s ease-out forwards; // Applique l'animation 
    }

    &.hide {
      animation: slideToRight 0.3s ease-out forwards; // Applique l'animation
    }
  
    @media (min-width: 200px) and (max-width: 480px) {
    }
    @media (min-width: 481px) and (max-width: 768px) {
    }
    @media (min-width: 769px) and (max-width: 992px) {
    }
    @media (min-width: 993px) and (max-width: 1200px) {
    }
    @media (min-width: 1201px) and (max-width: 1400px) {
    }
    @media (min-width: 1401px) {
    }
    .headerLink {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; 
      width: 100%;
      .link {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 1rem;
        border-radius: $border-radius;
        gap: $gap-small;
        border: 2px solid transparent;
        cursor: pointer;
        text-wrap: nowrap;
        @media (min-width: 200px) and (max-width: 480px) {
        }
        @media (min-width: 481px) and (max-width: 768px) {
        }
        @media (min-width: 769px) and (max-width: 992px) {
        }
        @media (min-width: 993px) and (max-width: 1200px) {
        }
        @media (min-width: 1201px) and (max-width: 1400px) {
          // flex-direction: row;
          // justify-content: flex-start;
          // gap: 1rem;
        }
        @media (min-width: 1401px) {
        }
        .item {
          font-size: $font-size-base;
          font-weight: bold;
          color: $primary;
        }
      }
      .link:hover {
        border: 2px solid $primary;
      }
  
      .link_active {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 0rem;
        padding-right: 0rem;
        border-radius: $border-radius;
        gap: $gap-small;
        cursor: pointer;
        background-color: $primary;
        @media (min-width: 200px) and (max-width: 480px) {
        }
        @media (min-width: 481px) and (max-width: 768px) {
        }
        @media (min-width: 769px) and (max-width: 992px) {
        }
        @media (min-width: 993px) and (max-width: 1200px) {
        }
        @media (min-width: 1201px) and (max-width: 1400px) {
          // flex-direction: row;
          // justify-content: flex-start;
          // gap: 1rem;
          // padding-left: 0rem;
          // padding-right: 0rem;
        }
        @media (min-width: 1401px) {
        }
        .item {
          font-size: $font-size-base;
          font-weight: bold;
          color: $white;
        }
      }
      .disable {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        width: 100%;
        border-radius: $border-radius;
        cursor: default;
        @media (min-width: 200px) and (max-width: 480px) {
        }
        @media (min-width: 481px) and (max-width: 768px) {
        }
        @media (min-width: 769px) and (max-width: 992px) {
        }
        @media (min-width: 993px) and (max-width: 1200px) {
        }
        @media (min-width: 1201px) and (max-width: 1400px) {
          // flex-direction: row;
          // justify-content: flex-start;
          // gap: 1rem;
        }
        @media (min-width: 1401px) {
        }
        .item {
          font-size: $font-size-base;
          font-weight: bold;
          color: $secondary;
        }
      }
    }
    .profile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 2rem;  
      background-color: $bg-white; 
      width: 100%;
      .link {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0.5rem 0.56rem 0.5rem 0.56rem;
        width: 100%;
        border-radius: 2rem;      
        cursor: pointer;
        .avatar {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          padding: 1.2rem;
          .image {
            border-radius: 50%;
            width: 2.4rem;
            height: 2.4rem;
            cursor: pointer;
          }
        }
        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: $gap-base;
          font-size: $font-size-base;
          font-weight: bold;
          width: 100%;
          color: $secondary;
        }
      }
      .link:hover {
        background-color: $secondary;
        color: $white;
        border-radius: 2rem;
        .avatar {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          padding: 1.2rem;
          .image {
            border-radius: 50%;
            width: 2.4rem;
            height: 2.4rem;
            cursor: pointer;
          }
        }
        .item {
          color: $white;
        }
      }
      .link_active {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0.5rem 0.56rem 0.5rem 0.56rem;
        width: 100%;
        border-radius: 2rem;  
        cursor: pointer;
        background-color: $secondary;
        color: $white;
        border-radius: 2rem;
        .avatar {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          padding: 1.2rem;
          .image {
            border-radius: 50%;
            width: 2.4rem;
            height: 2.4rem;
            cursor: pointer;
          }
        }
        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: $gap-base;
          font-size: $title4;
          font-weight: bold;
          width: 100%;
          color: $white;
        }
      }
      .disable {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0.25rem 1rem 0.25rem 1rem;
        width: 100%;
        border-radius: 2rem;
        :hover{
          cursor:default;
        }
        .picto {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 3rem;
          height: 3rem;
          border-radius: $border-radius;
          .icon {
            fill: $gray;
            width: 1.5rem;
            height: 1.5rem;
          }
        }
        .item {
          display: flex;
          padding-left: 0.5rem;
          font-size: $title4;
          font-weight: bold;
          width: 100%;
          color: $gray;
        }
      } 
    }
    .helper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $bg-white;
      border-radius: 1.75rem;
      width: 100%;
      padding: 0.9rem;
      .link {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        font-size: $font-size-base;
        font-weight: bold;
        padding: 0rem;
      }
    }
  }
  
  @keyframes slideToRight {
    0% {
      transform: translateX(0); // Coin supérieur droit hors écran
      opacity: 1;
    }
    100% {
      transform: translateX(100%); // Position finale
      opacity: 1;
      display: none;
    }
  }
  
  @keyframes slideToLeft {
    0% {
      display: none;
      transform: translateX(100%); // Coin supérieur droit hors écran
      opacity: 0;
    }
    100% {
      transform: translateX(0); // Position finale
      opacity: 1;
    }
  }
}


