@import "../../styles/app";

.root {
  @extend %page-base;
  .content {
    @extend %page-content;
    .title {
      color: $primary ;
      font-size: $title4;
      font-weight: bold;
      padding-left: 1.5rem;
    }
    .control {
      display: flex;
      align-items: center;
    }
  }
}