%header-base {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: $gap-base;
  background-color: $bg-white;
  border-radius: 2.75rem 2.75rem 0rem 0rem;
  padding: 1rem;
  @media (min-width: 200px) and (max-width: 480px) {
    border-radius: 0rem;
    flex-direction: column;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    border-radius: 0rem;
    flex-direction: column;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    flex-direction: column;
  }
  @media (min-width: 993px) and (max-width: 1200px) {
    flex-direction: column;
  }
  @media (min-width: 1201px) and (max-width: 1400px) {
  }
  @media (min-width: 1401px) {
  }
}

%page-base {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 2rem);
  overflow: hidden;
  @media (min-width: 200px) and (max-width: 480px) {
    height: calc(100vh - 5rem);
  }
  @media (min-width: 481px) and (max-width: 768px) {
    height: calc(100vh - 5rem);
  }
  @media (min-width: 769px) and (max-width: 992px) {
  }
  @media (min-width: 993px) and (max-width: 1200px) {
  }
  @media (min-width: 1201px) and (max-width: 1400px) {
  }
  @media (min-width: 1401px) {
  }
}

%page-content {
  display: flex;
  flex-direction: column;
  gap: $gap-base;
  flex-grow: 1;
  overflow-y: auto;
  padding: 0.5rem 1rem 1rem 1rem;
  scrollbar-width: none;
  background-color: $bg-white;
  border-radius: 0 0 2.75rem 2.75rem;
  @media (min-width: 200px) and (max-width: 480px) {
    height: calc(100vh - 5rem);
    border-radius: 0rem;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    height: calc(100vh - 5rem);
    border-radius: 0rem;
  }
  @media (min-width: 769px) and (max-width: 992px) {
  }
  @media (min-width: 993px) and (max-width: 1200px) {
  }
  @media (min-width: 1201px) and (max-width: 1400px) {
  }
  @media (min-width: 1401px) {
  }
}
