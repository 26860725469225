@import "../../../../styles/app";

.root {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 1rem);
  overflow-y: hidden;
  background-color: $bg-white;
  border-radius: 0 0 2rem 2rem;
  @media (min-width: 200px) and (max-width: 480px) {
    height: calc(100vh - 2rem);
    border-radius: 0rem;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    height: calc(100vh - 2rem);
    border-radius: 0rem;
  }
  @media (min-width: 769px) and (max-width: 992px) {
  }
  @media (min-width: 993px) and (max-width: 1200px) {
  }
  @media (min-width: 1201px) and (max-width: 1400px) {
  }
  @media (min-width: 1401px) {
  }
  .header {
    display: grid;
    grid-template-columns: 2fr 0.6fr 0.5fr;
    grid-template-areas:
    "searchBar btnWarning btnAdd";
    justify-content: space-between;
    align-items: center;
    padding: 0rem 0rem 1rem 0rem;
    gap: $gap-high;
    @media (min-width: 200px) and (max-width: 1200px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-areas:
      "searchBar searchBar searchBar searchBar"
      "btnWarning btnWarning btnAdd btnAdd";
    }
    .search {
      grid-area: searchBar;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 2;
      .formGroup {
        display: flex;
        width: 100%;
        margin-bottom: 0rem !important;
        .input {
          border-radius: 2rem 0rem 0rem 2rem;  
          padding: 0.5rem;
          background-color: $bg-white;
          border-right: none;
          outline: none;
          box-shadow: none;
          padding: 1rem;
          padding-left: 1rem;
        }
        .inputGroupText {
          border-radius: 0rem 2rem 2rem 0rem;  
          border-left: none;
          background-color: $bg-white;
        }
      }
    }
    .btnWarning {
      grid-area: btnWarning;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: $warning;
      border: 2px solid transparent;
      border-radius: $border-radius;
      padding: 1rem 1rem 1rem 0.5rem;
      gap: $gap-small;
    }
    .btn {
      grid-area: btnAdd;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: $primary;
      border: 2px solid transparent;
      border-radius: $border-radius;
      padding: 1rem 1rem 1rem 0.5rem;
      gap: $gap-small;

      &:hover {
        background-color: $bg-app;
        border-color: $primary;
        color:$primary;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: $gap-high;
    flex-grow: 1;
    overflow-y: auto;
    padding: 1.5rem 1rem 0.5rem 1rem;
    scrollbar-width: none;
    .title {
      display: flex;
      justify-content: flex-start;
      font-size: $title4;
      color: $secondary;
      font-weight: 700;
      gap: 0.5rem;

      >span {
        color: $primary;
      }
    }
    .devices {
      display: flex;
      flex-wrap: wrap;
      gap: $gap-base;
      .noResult {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $title4;
        color: $secondary;
        font-weight: 700;
        gap: 0.5rem;
        width: 100%;
      }
    }
  }
}

