@import '../../../../../../styles/app.scss';

.root {
  display: flex;
  flex-direction: column;
  border-radius: 1.5rem;
  background-color: $bg-app;
  border: 2px solid $bg-app;
  width: 32%;
  padding: 1.5rem;
  gap: $gap-small;
  transition: border-color 0.2s ease;
  @media (min-width: 350px) and (max-width: 480px) {
    width: 100%;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    width: 100%;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    width: 100%;
  }
  @media (min-width: 993px) and (max-width: 1200px) {
    width: 100%;
  }
  @media (min-width: 1201px) and (max-width: 1400px) {
    width: 45%;
  }
  @media (min-width: 1401px) and (max-width:1488px) {
    width: 40%;
  }
  &:hover {
    border: solid 2px $primary;
    .header .control .unlink {
      opacity: 1;
    }
    .header .control .modify {
      opacity: 1;
    }
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .title {
      font-size: $title3;
      color: $secondary;
    }
    .control {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .unlink {
        display: flex;
        padding: 0.5rem;
        align-items: center;
        gap: $gap-small;
        color: $danger;
        border-radius: $border-radius;
        opacity: 0;
        transition: opacity 0.2s ease, transform 0.5s ease;
        &:hover {
          background-color: $red-transparent;
          cursor: pointer;
        }
      }
      .modify {
        padding: 0.5rem;
        align-items: center;
        border-radius: $border-radius;
        opacity: 0;
        transition: opacity 0.2s ease, transform 0.5s ease;
        &:hover {
          background-color: $bg-white;
          cursor: pointer;
        }
      }
    }
  }
  .infos {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .affectations {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 1rem;
      margin-bottom: 0.5rem;
      .label {
        display: flex;
        flex-direction: row;
        gap: $gap-small;
        background-color: $bg-white;
        padding:  0.5rem 1rem;
        border-radius: $border-radius;
        >span{
          color: $secondary;
        }
      }
    }
    .info {
      display: flex;
      flex-direction: row;
      width: 100%;
      flex-wrap: wrap;
      gap: $gap-small;
      > span {
        font-weight: 700;
      }
    }
  }
}
