@import '../../../../../styles/app.scss';

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: $gap-high;
  .selectBuilding {
    display: flex;
    flex-direction: column;
    gap: $gap-small;
    .title{
        font-size: $font-size-base;
        font-weight: 700;
    }
    .formGroupBuilding {
      display: flex;
      flex-direction: row;
      position: relative;
      width: 100%;
      margin-bottom: 0rem !important;
      border-radius: 1.75rem;
      .inputGroupBuilding {
        height: 100%;
        border: none;
        outline: none;
        .inputGroupText {
          background-color: $bg-white;
          border-radius: 1.75rem 0 0 1.75rem;
          border: none;
          outline: none;
          cursor: pointer;
          @media (min-width: 200px) and (max-width: 480px) {
            display: none;
          }
          @media (min-width: 481px) and (max-width: 768px) {
          }
          @media (min-width: 769px) and (max-width: 992px) {
          }
          @media (min-width: 993px) and (max-width: 1200px) {
          }
          @media (min-width: 1201px) and (max-width: 1400px) {
          }
          @media (min-width: 1401px) {
          }
          > span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 3rem;
            height: 3rem;
            border-radius: 1.5rem;
            background-color: $bg-app;
          } 
        }
        .inputGroupText:hover {
          background-color: $bg-white;
        }
        .input {
          display: flex;
          align-items: center;
          background-color: $bg-app;
          font-size: $font-size-base;
          box-shadow: none;
          outline: none;
          border: none;
          cursor: pointer;
          @media (min-width: 200px) and (max-width: 480px) {
            background-color: transparent;
          }
          @media (min-width: 481px) and (max-width: 768px) {
          }
          @media (min-width: 769px) and (max-width: 992px) {
          }
          @media (min-width: 993px) and (max-width: 1200px) {
          }
          @media (min-width: 1201px) and (max-width: 1400px) {
          }
          @media (min-width: 1401px) {
          }
          .activeText{
            color: $primary;
            font-weight: bold;
            border-radius: 0rem;
          }
        }
      }
      .listGroup {
        position: absolute;
        bottom: 100%; 
        transform-origin: bottom; 
        background-color: $bg-white;
        z-index: 1050;
        border: 1px solid $light-gray;
        border-radius: 1.75rem;
        width: 70%; 
        left: 30%;
        max-height: 10rem; 
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        .item {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: $gap-small;
          padding-left: 1rem;
          padding-right: 1rem;
          width: 100%;
          .name {
            display: flex;
            justify-content: flex-start;
            background-color: transparent;
            border: none;
          }
        }
        .item:hover:first-child {
          border-radius: $border-radius $border-radius 0 0;
        }
        .item:hover:last-child {
          border-radius: 0 0 $border-radius $border-radius;
        }
        .item:hover {
          background-color: $light-gray;
        }
      }
    }
  }
  .selectRoom {
    display: flex;
    flex-direction: column;
    gap: $gap-small;
    .title{
      font-size: $font-size-base;
      font-weight: 700;
    }
    .formGroupRoom {
      display: flex;
      flex-direction: row;
      position: relative;
      width: 100%;
      margin-bottom: 0rem !important;
      border-radius: 1.75rem;
      .inputGroupRoom {
        border: none;
        outline: none;
        @media (min-width: 200px) and (max-width: 480px) {
        height: 4rem;
        }
        @media (min-width: 481px) and (max-width: 768px) {
        }
        @media (min-width: 769px) and (max-width: 992px) {
        }
        @media (min-width: 993px) and (max-width: 1200px) {
        }
        @media (min-width: 1201px) and (max-width: 1400px) {
        }
        @media (min-width: 1401px) {
        }
        .inputGroupText {
          background-color: $bg-white;
          border-radius: $border-radius;
          border: none;
          outline: none;
          cursor: pointer;
          @media (min-width: 200px) and (max-width: 480px) {
              display: none;
          }
          @media (min-width: 481px) and (max-width: 768px) {
          }
          @media (min-width: 769px) and (max-width: 992px) {
          }
          @media (min-width: 993px) and (max-width: 1200px) {
          }
          @media (min-width: 1201px) and (max-width: 1400px) {
          }
          @media (min-width: 1401px) {
          }
          > span {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 3rem;
              height: 3rem;
              border-radius: 2rem;
              background-color: $bg-app;
          } 
        }
        .inputGroupText:last-child {
          background-color: $bg-white;
          border-radius: $border-radius;
          cursor: pointer;
          > span {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 3rem;
              height: 3rem;
              border-radius: 0rem;
              background-color: transparent;
            } 
        }
        .inputGroupText:hover {
          background-color: $bg-white;
        }
        .input {
          display: flex;
          align-items: center;
          background-color: $bg-app;
          font-size: $font-size-base;
          box-shadow: none;
          outline: none;
          border: none;
          cursor: pointer;
          height: 100%;
          @media (min-width: 200px) and (max-width: 480px) {
              background-color: transparent;
          }
          @media (min-width: 481px) and (max-width: 768px) {
          }
          @media (min-width: 769px) and (max-width: 992px) {
          }
          @media (min-width: 993px) and (max-width: 1200px) {
          }
          @media (min-width: 1201px) and (max-width: 1400px) {
          }
          @media (min-width: 1401px) {
          }
          .activeText {
            color: $primary;
            font-weight: bold;
            border-radius: 0rem;
          }
        }
      }
      .listGroup {
        position: absolute;
        bottom: 100%; 
        transform-origin: bottom; 
        background-color: $bg-white;
        z-index: 1050;
        border: 1px solid $light-gray;
        border-radius: 1.75rem;
        width: 70%; 
        left: 30%;
        max-height: 20rem; 
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        .item {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: $gap-small;
          padding-left: 1rem;
          padding-right: 1rem;
          width: 100%;
          .name {
            display: flex;
            justify-content: flex-start;
            background-color: transparent;
            border: none;
          }
        }
        .item:first-child {
          border: 1px solid $bg-app;
        }
        .item:hover:first-child {
          border-radius: $border-radius;
        }
        .item:hover:last-child {
          border-radius: $border-radius;
        }
        .item:hover {
          background-color: $light-gray;
        }
      }
    }
  }
  .btnSteps {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .btn {
      background-color: $bg-white;
      border: 1px solid $primary;
      display: flex;
      justify-content: flex-end;
      color: $primary;
      align-items: center;
      padding: 0.5rem 1rem 0.5rem 1rem;
      border-radius: $border-radius;
      gap: $gap-small;
      cursor: pointer;
    }
    .active {
      background-color: $primary;
      border: 1px solid $primary;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: $bg-white;
      padding: 0.5rem 1rem 0.5rem 1rem;
      border-radius: $border-radius;
      gap: $gap-small;
      cursor: pointer;
    }
    .disable {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 1rem 0.5rem 1rem;
      border-radius: $border-radius;
      border: 1px solid $gray-500;
      color: $bg-white;
      gap: $gap-small;
      background-color: $gray-500;
      cursor: default;
    }
  }
}