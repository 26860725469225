.page-title {
  color: $black;
  font-weight: 300;
  line-height: 1;
  /* stylelint-disable-next-line */
  margin: 11px 0 (1.5 * $spacer);

  small {
    color: rgba($white, 0.7);
  }

  // @media (max-width: breakpoint-max(sm)) {
  //   margin-top: 0;
  // }
}

.page-top-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  & .close {
    margin-left: 25px;
  }
}
.form-group {
  .form-control-plaintext {
    padding: 0.75rem 0.5rem;
    border-radius: $border-radius;
    border: none;
    background-color: $bg-custom-dark;
    color: $black;
  }
}
.form-control {
  color: $black;
  &:focus,
  &:active {
    color: $black;
  }
  &.input-transparent {
    border: none;
    color: $black;
    background-color: #12142b;
    opacity: 0.8;

    &:active,
    &:focus {
      background-color: $bg-custom-dark !important;

      @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.2));
    }

    &[disabled],
    &[readonly],
    fieldset[disabled] & {
      background-color: $light-gray;
    }
  }
}

.input-group.focus {
  .input-group-addon,
  input {
    background-color: $bg-white;
  }
}

.animated {
  animation-duration: 0.5s;
}

.chat-notification-sing {
  position: absolute;
  top: 12px;
  left: 14px;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: theme-color($danger);
}

#notification-buttons {
  display: flex;

  button {
    flex: 1;
    font-family: $font-family-base;
    font-size: $font-size-small;
    background-color: $bg-white;

    &.active {
      color: #343434;
      background-color: $gray-100;
      background-image: none;
      border-color: $gray-300;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.12);
    }
  }
}

.btn-toolbar .btn {
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

/* --------- Forms --------- */
.form-action {
  background-color: $bg-white;
  margin: $spacer 0 0;
  padding: calc($spacer/2) $spacer;
}

.widget-top-overflow,
.widget-middle-overflow {
  position: relative;
  margin: 0 (-1.2rem);

  > img {
    max-width: 100%;
  }
}

.widget-top-overflow {
  margin-top: 2 * (-1rem);
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  overflow: hidden;

  > img {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  > .btn {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    margin-right: 1.2rem;

    // @include media-breakpoint-up(md) {
    //   top: auto;
    //   bottom: 0;
    // }
  }
}

.widget-table-overflow {
  margin: 0 (-1.2rem) (-1rem);

  th:first-child,
  td:first-child {
    padding-left: 1.2rem;
  }

  th:last-child,
  td:last-child {
    padding-right:1.2rem;
  }

  tr:last-child {
    td:first-child {
      border-bottom-left-radius: $border-radius;
    }

    td:last-child {
      border-bottom-right-radius: $border-radius;
    }
  }
}

table > thead > tr > th {
  border-top: none !important;
  border-bottom-width: 1px !important;
}

/* --------- Pagination --------- */
.pagination {
  font-weight: 400;

  > .page-item {
    display: inline; // Remove list-style and block-level defaults

    > .page-link,
    > span {
      border-radius: $border-radius;
      margin: 0 2px;
    }

    > .page-link {
      text-decoration: none;
      border: none;
    }
  }
}

/* --------- Stats Row --------- */
.stats-row {
  margin-bottom: 1.25rem;
  display: flex;
  align-items: center;
}

.stat-item {
  display: inline-block;
  padding-right: 1.25rem;

  & + .stat-item {
    padding-left: 1.25rem;
    border-left: 1px solid $hr-border-color;
  }

  .name {
    margin-bottom: 0.25rem;
    font-size: $font-size-small;
  }

  &.stat-item-mini-chart {
    position: relative;
    top: -1rem;
    padding-left: 0;
    border-left: none;
  }
}

/*         Widgets Grid          */

.widget-container {
  min-height: 30px;

  .widget:not(.fullscreened) > header {
    cursor: move;
  }
}

.widget-placeholder {
  background: rgba($white, 0.4);
  border: 1px dashed $gray;
  margin: -1px -1px calc(#{$grid-gutter-width} - 1px) -1px;
}

.sortable-chosen {
  opacity: 1 !important;
}

.widget-placeholder-react {
  background: rgba($white, 0.4) !important;
  border: 1px dashed $gray !important;
  margin: -1px -1px calc(#{$grid-gutter-width} - 1px) -1px !important;
  > * {
    opacity: 0;
  }
}

/*         Post Links           */

.post-links {
  margin-bottom: 0;
  font-size: $font-size-small;
  padding-left: 0;

  @include clearfix();

  > li {
    float: left;
    list-style: none;

    + li {
      &::before {
        color: #999;
        content: '\25cf';
        padding: 0 8px;
      }
    }

    > a {
      text-decoration: none;
      color: $light-gray;

      &:hover {
        color: $light-gray; //text-muted
      }
    }
  }

  &.no-separator > li + li {
    margin-left: 12px;

    &::before {
      content: normal;
    }
  }
}

/*          Post Comments           */

.post-comments {
  margin-bottom: calc($spacer/2);
  margin-left: -20px;
  margin-right: -20px;
  font-size: $font-size-small;

  @include clearfix();

  > li {
    padding: 10px;
    border-top: 1px solid #e7e7e7;
    list-style: none;

    @include clearfix();
  }

  p:last-child {
    margin-bottom: 0;
  }

  .avatar {
    margin-top: 1px;
  }

  .author {
    margin-top: 0;
    margin-bottom: 2px;
    color: $primary;
  }

  .comment-body {
    overflow: auto;
  }

  h6.author > small {
    font-size: 11px;
  }

  .widget > footer & {
    margin-left: -1.2rem;
    margin-right: -1.2rem;
  }
}

.btn-link {
  background: transparent;
  border: 0;
  outline: 0;
  color: $link-color;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
}

.bg-widget-transparent {
  background-color: $light-gray;
}
