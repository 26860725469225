@import '../../../../styles/app.scss';

.root {
  display: flex;
  flex-direction: column;
  background-color: $bg-white;
  border: solid 2px $bg-white;
  border-radius: $border-radius;
  padding: 1rem;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: $gap-small;
    .title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: $secondary;
      gap: $gap-small;
      > span:last-child {
        font-size: $font-size-base;
        font-weight: bold;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: $gap-base;
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: $gap-small;
      width: 50%;
      .value {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 0rem 0.5rem 0rem;
        font-size:  $title2;
        font-weight: 300;
        color: $secondary;
      }
      .not_value {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: $bg-white;
        border-radius: 2rem;
        padding: 0.5rem 0rem 0.5rem 0rem;
        font-size: clamp($font-size-base, 1vw, $title5);
        color: $no_values;
        gap: $gap-small;
        width: 50%;
        height: 2.5rem;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: center;
    color: $secondary;
    .description {
      color: $secondary;
      margin-bottom: 0rem;
      text-align: center;
    }
  }
}
