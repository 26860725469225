@import "../../../../styles/app";

.root {
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem 1rem 0rem;
  
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px outset $white;
    .title {
      color: $secondary;
      font-size: $title3;
      font-weight: bold;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: $gap-base;
    padding-top: 1rem;
    .account {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .item {
        display: flex;
        flex-direction: row;
        gap: $gap-base;
        .label {
          color: $secondary;
          font-weight: bold;
        }
        .status {
          color: $secondary;
          font-weight: bold;
        }
      }
    }
  }
}
