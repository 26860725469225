@import "../../../../styles/app";

.root {
  display:  grid;
  grid-template-columns: 1fr 1.5fr 0.25fr;
  grid-template-areas:
    'header content control';
  column-gap: $gap-base;
  row-gap: $gap-base;
  width: 100%;
  padding: 1rem;
  background-color: $bg-white;
  border-bottom: 2px solid $light-gray;
  @media (min-width: 200px) and (max-width: 480px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'header control' 
      'content content';
  }
  @media (min-width: 481px) and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'header control' 
      'content content';
  }
  @media (min-width: 769px) and (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'header control' 
      'content content';
  }
  @media (min-width: 993px) and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      'header . control' 
      'content content content';
  }
  @media (min-width: 1201px) and (max-width: 1400px) {
    grid-template-columns: 1fr 1.5fr 0.25fr;
    grid-template-areas:
      'header . control' 
      'content content content';
  }
  @media (min-width: 1401px) {
    grid-template-columns: 1fr 1.5fr 0.25fr;
    grid-template-areas:
      'header content control';
  }

  .header {
    grid-area: header;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .title {
      display: flex;
      align-items: center;
      font-size: clamp($title3, 2vw, $title1);
      color: $secondary;
    }
    .building_location {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: $font-size-small;
      font-weight: 700;
      gap: $gap-small;
      color: $secondary;
    }
  }

  .content {
    grid-area: content;
    display: flex;
    gap: $gap-base;
    width: 100%;
    > button {
      display: flex;
      align-items: center;
      flex: 2;
    }
  }


  .control {
    grid-area: control;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

}
.root:last-child {
  border-bottom: none;
}