@import '../../../../../../styles/app.scss';

.contentAdd {
  display: flex;
  flex-direction: column;
  gap: $gap-base;
  flex-grow: 1;
  overflow-y: auto;
  padding: 0rem 1rem 0rem 0rem;
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    .formGroup {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-bottom: 0 !important;
      .priceInput {
        display: flex;
        flex-direction: row;
        justify-content: end;
        .priceText {
          border-radius: 2rem 0rem 0rem 2rem;
          width: 65%;
        }
        .priceUnity {
          display: flex;
          flex-direction: column;
          justify-content: end;
          border-radius: 0rem 2rem 2rem 0rem;
          width: 35%;
        }
      }
      .priceMonthInput {
        display: flex;
        flex-direction: row;
        justify-content: end;
        .priceText {
          border-radius: 2rem 0rem 0rem 2rem;
          width: 82%;
        }
        .priceUnity {
          display: flex;
          flex-direction: column;
          justify-content: end;
          border-radius: 0rem 2rem 2rem 0rem;
          width: 18%;
        }
      }
    }
    .checkBox {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
      padding-left: 0.5rem;
      margin-bottom: 0 !important;
    }
    .contract {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 1rem;
      .formGroup {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-bottom: 0 !important;
      }
    }
  }
  .btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
