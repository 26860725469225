@import "../../../styles/app";

.header {
  // @extend %header-base;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $bg-white;
  padding: 1rem;
  border-radius: 2.75rem 2.75rem 0rem 0rem;
  .title {
    color: $secondary;
    font-size:clamp($title3, 3vw, $title1);
  }
  .disconnect {
    display: flex;
    justify-content: center;
  }
  .avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 6.5rem;
    height: 6.5rem;
    border: 2px solid $secondary;
    border-radius: 52px;
    .delete {
      position: absolute;
      top: 1%;
      left: 88%;
      .icon {
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 0.9rem;
        cursor: pointer;
      }
    }
    .image {
      border-radius: 50px;
      width: 100px;
      height: 100px;
      cursor: pointer;
    }
    .overlay_circle {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 4.2rem;
      bottom: 0;
      right: 0;
      width: 3.2rem;
      height: 3.2rem;
      background-color:  $white;
      border: 2px solid $secondary;
      border-radius: 35%;
      cursor: pointer;
      .icon{
        padding-top: 5rem;
      }
    }
  } 
}