@import "../../../../styles/app";

.root {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  border: 2px solid $light-gray;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    background-color: $light-gray;
    .title {
      display: flex;
      flex-direction: row;
      font-size: $title4;
      font-weight: bold;
      color: $secondary;
    }
  }
  .content {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    margin-bottom: 0rem;
    width: 100%;
    height: 75%;
    .cardInfo {
      display: flex;
      flex-direction: column;
      margin-bottom: 0rem;
      width: 100%;
      justify-content: center;
      gap: $gap-base;
      > li {
        display: flex;
        flex-direction: row;
        gap: $gap-base;
        font-size: $font-size-base;
        justify-content: flex-start;
        align-items: center;
        > span:first-child {
          display: flex;
          flex: 1;
          font-weight: bold;
          color: $secondary;
        }
        > span:last-child {
          display: flex;
          
        }
        .themeBall{
          width: 2rem;
          height: 2rem;
          background-color: $primary;
          border: 1px solid $primary;
          background-image: -webkit-linear-gradient(135deg, $primary 51%, $bg-white 49%);
          border-radius: 2rem;
        }
      }
    }
  }
}
