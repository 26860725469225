@import "../../styles/app";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius;
  width: 30%;
  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 1rem 1rem 1rem;
    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: $title1;
      color: $secondary;
    }
    .description {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: $title1;
      color: $secondary;
    }
  }

  .content {
    display: flex;
    justify-content: center;
    width: 100%;
    .icon {
      width: 50%;
    }
  }
  @media (min-width: 200px) and (max-width: 480px) {
   
    width: 100%;
  }
  @media (min-width: 481px) and (max-width: 768px) {
  }
  @media (min-width: 769px) and (max-width: 992px) {
  }
  @media (min-width: 993px) and (max-width: 1200px) {
  }
  @media (min-width: 1201px) and (max-width: 1400px) {
  }
  @media (min-width: 1401px) {
  }
}