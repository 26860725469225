@import '../../styles/app';

.root {
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  // border: 1px dashed white;
  justify-content: center;
  align-items: center;
  .logo_animate {
    display: flex;
    position: relative;
    // border: 1px dashed white;
    width: 36px;
    height: 36px;
  }
  .logo_animate div {
    position: absolute;
    top: 0px;
    width: 14px;
    height: 14px;
    border-radius: 2px;
    background: $secondary;
    animation: logo_animate 0.6s ease-in-out infinite;
  }

  .logo_animate div:nth-child(1) {
    top: 2px;
    left: 2px;
    opacity: 1;
    transform: rotate(0deg);
    animation-delay: -0.45s;
  }
  .logo_animate div:nth-child(2) {
    top: 2px;
    left: 19px;
    opacity: 1;
    transform: rotate(45deg);
    animation-delay: -0.30s;
  }
  .logo_animate div:nth-child(3) {
    left: 19px;
    top: 19px;
    opacity: 1;
    transform: rotate(0deg);
    animation-delay: -0.15s;
  }
  .logo_animate div:nth-child(4) {
    left: 2px;
    top: 19px;
    opacity: 1;
    transform: rotate(45deg);
    animation-delay: 0s;
  }
  @keyframes logo_animate {
    0% {
      opacity: 0.5;
      box-shadow: 0px 0px 0px $white;
    }
    25% {
      opacity: 0.5;
      box-shadow: 0px 0px 2px $white;
    }
    50% {
      opacity: 1;
      box-shadow: 0px 0px 4px $white;
    }
    75% {
      opacity: 0.5;
      box-shadow: 0px 0px 2px $white;
    }
    100% {
      opacity: 0.5;
      box-shadow: 0px 0px 0px $white;
    }
  }
}
