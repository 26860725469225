#root {
  ///form reactstrap
  .btn-primary {
    --bs-btn-color: #{$white};
    --bs-btn-hover-color: #{$white};
    --bs-btn-disabled-color: #{$white};
    --bs-btn-active-color: #{$white};
  }
  
  .btn-outline-primary {
    --bs-btn-hover-color: #{$white};
    --bs-btn-hover-bg: #{$primary};
    --bs-btn-hover-border-color: #{$primary};
    --bs-btn-border-width: 2px;
    --bs-btn-active-color: #{$white};
  }

  .btn-outline-secondary {
    --bs-btn-hover-color: #{$secondary};
    --bs-btn-hover-bg: transparent;
    --bs-btn-hover-border-color: #{$secondary};
    --bs-btn-border-width: 2px;
  }

  .btn-outline-danger {
    --bs-btn-hover-color: #{$danger};
    --bs-btn-hover-bg: transparent;
    --bs-btn-hover-border-color: #{$danger};
    --bs-btn-border-width: 2px;
  }

  .form-control {
    border: none;
    background-color: $light-primary;
    box-shadow: none;
  }

  .form-label {
    font-weight: bold;
    margin-bottom: 0rem;
  }

  .form-text {
    font-weight: 700;
    text-decoration: underline;
    text-decoration-color: $primary;
    color: $primary;
    :hover {
      text-decoration-color: $primary;
      color: $primary;
    }
  }

  .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: 0;
    border-left: none;
  }

  .input-group-text {
    background-color: $light-primary;
    border: none;
  }
}




  

