$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;

@mixin responive($breakpoint) {
  @if $breakpoint == xs {
    @media (max-width: #{($breakpoint-sm - 1)}) { @content; }
  } @else if $breakpoint == sm {
    @media (min-width: $breakpoint-sm) and (max-width: #{($breakpoint-md - 1)}) { @content; }
  } @else if $breakpoint == md {
    @media (min-width: $breakpoint-md) and (max-width: #{($breakpoint-lg - 1)}) { @content; }
  } @else if $breakpoint == lg {
    @media (min-width: $breakpoint-lg) and (max-width: #{($breakpoint-xl - 1)}) { @content; }
  } @else if $breakpoint == xl {
    @media (min-width: $breakpoint-xl) { @content; }
  }
}

@mixin rotateRight {
  animation: scaleRotateRightEffect 2s linear;
}

@mixin rotateLeft {
  animation: scaleRotateLeftEffect 2s linear;
}

@keyframes scaleRotateRightEffect {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1) rotate(180deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}

@keyframes scaleRotateLeftEffect {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1) rotate(-180deg);
  }
  100% {
    transform: scale(1) rotate(-360deg);
  }
}


