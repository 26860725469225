@import '../../../../styles/app.scss';

.root {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: $bg-white;
  border-radius: $border-radius;
  .header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .label {
      font-size: $font-size-base;
      font-weight: 600;
      color: $secondary;
    }
    .period {
      color: $secondary;
    }
  }
  .middleDates {
    display: flex;
    justify-content: center;
    .middleDate {
      font-size: $font-size-base;
      font-weight: 600;
      color: $secondary;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0rem;
    .measure {
      display: flex;
      flex-direction: row;
      .last {
        display: flex;
        justify-content: center;
        padding: 0.5625rem 2.59375rem 0.5625rem 2.71875rem;
        background-color: $bg-app;
        width: 100%;
        border-radius: 1.25rem 0rem;
        color: $secondary;
        font-size: $font-size-small;
        font-style: normal;
        font-weight: 700;
      }
      .current {
        display: flex;
        justify-content: center;
        padding: 0.5625rem 2.59375rem 0.5625rem 2.71875rem;
        background-color: $primary;
        border-radius: 0rem 1.25rem;
        color: $bg-white;
        width: 100%;
        font-size: $font-size-small;
        font-style: normal;
        font-weight: 700;
      }
    }
    .linesMiddle {
      width: 100%;
      display: flex;
      flex-direction: row;
      padding: 0rem 0rem 0rem 0.1rem;
      justify-content: center;
      .lineMiddle {
        width: 0.1rem;
        height: 0.5rem;
        background-color: $primary;
      }
    }
    .lines {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .lineCurrent{
        width: 0.15rem;
        height: 0.5rem;
        background-color: $primary;
      }
      .lineLast{
        width: 0.1rem;
        height: 0.5rem;
        background-color: $bg-app;
      }
    }
    .description {
      color: $secondary;
    }
  }
  .dates {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .label {
      font-size: $font-size-base;
      font-weight: 600;
      color: $secondary;
    }
  }
  .button {
    .btn {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-color: $primary;
      border-radius: 2rem;
      background-color: $primary;
      color: $bg-white;
      width: 100%;
    }
  }
}
