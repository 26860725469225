@import '../../../../styles/app.scss';

.root {
  display: flex;
  flex-direction: column;
  border-radius: 1.5rem;
  background-color: $gray-200;
  border: 2px solid $gray-200;
  width: 100%;
  padding: 1.5rem;
  gap: $gap-high;
  transition: border-color 0.2s ease;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .title {
      font-size: $title2;
      color: $secondary;
    }
    .arrowIcon {
      display: flex;
      align-items: center;
      opacity: 0;
      transform: translateX(-120px);
      transition:
        opacity 0.2s ease,
        transform 0.5s ease;
    }
    .trashIcon {
      display: flex;
      border-radius: 2.5rem;
      justify-content: center;
      align-items: center;
      height: fit-content;
      padding: 0.5rem;
      &:hover {
        background-color: $red-transparent;
      }
    }
  }
  .infos {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .info {
      display: flex;
      flex-direction: row;
      width: 100%;
      flex-wrap: wrap;
      gap: $gap-small;
      > span {
        font-weight: 700;
      }
    }
  }
}
