.password_checklist li {
  list-style-type: none;
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.password_checklist li::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 8px;
}

.password_checklist li.valid::before {
  background-image: url("../../../assets/password/valid.png");
}

.password_checklist li.invalid::before {
  background-image: url("../../../assets/password/invalid.png");
  opacity: 0.5;
}