@import "../../../../../../../../styles/app";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $gap-base;
  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 2;
    gap: $gap-base;
    .description {
      display: flex;
      flex-direction: column;
      width: 100%;
      font-size: $font-size-base;
    }
    .form {
      display: flex;
      flex-direction: column;
      border-radius: 1rem;
      gap: $gap-base;
      width: 100%;
      .checkgroup {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        gap:1rem;
        margin-bottom: 0rem;
        .label {
          width: 100%;
          margin: 0;
        }
        .checkbox {
          margin: 0;
        }
        .checkbox:hover {
          cursor: pointer;
        }
      }
    }
  }
  .control {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: $gap-base;
    button {
      width: 25%;
    }
  }
}