@import "../../../../../../styles/app";

.root {
  display: flex;
  flex-direction: column;
  border-radius: 1.5rem 1.5rem 1.5rem 1.5rem;
  border: 2px solid $bg_app;
  background-color: $bg_app;
  width: 30rem;
  height: 100%;
  padding: 1rem;
  gap: $gap-base;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 1.5rem 1.5rem 0 0;
    .title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: $title4;
      font-weight: bold;
      color: $secondary;
    }
    .control {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      .delete {
        border-radius: 2rem;
        padding: 0.5rem;
        &:hover {
          background-color: $danger-transparent;
        }
      }
      .modify {
        border-radius: 2rem;
        padding: 0.5rem;
        &:hover {
          background-color: $bg-white;
        }
      }
    }
  }
  .content {
    display: flex;
    flex-direction: row;
    margin-bottom: 0rem;
    width: 100%;
    .info {
      display: flex;
      flex-direction: column;
      margin-bottom: 0rem;
      width: 100%;
      > li {
        display: flex;
        flex-direction: row;
        gap: $gap-base;
        font-size: $font-size-small;
        > span:first-child {
          display: flex;
          flex: 1;
          font-weight: bold;
          font-size: $font-size-base;
          color: $secondary;
        }
        > span:last-child {
          display: flex;
          font-size: $font-size-base;
        }
      }
    }
  }
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    > span {
      color: $secondary;
      font-weight: 600;
    }
  }
}
