


legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: $spacer;
  line-height: inherit;
  color: $light-gray; //text-muted
  border: 0;
  border-bottom: 1px solid #e5e5e5;
  font-size: 15px;
  font-weight: 400;
}


.alert {
  font-size: $font-size-small;

  .close {
    color: $background-modal;
  }
}
//Only use in login page
.alert-sm {
  padding: 10px 15px;

  .close {
    font-size: 20px;
    top: 0;
    right: -8px;
  }
}

.small,
small {
  font-size: 85%;
}

///////// Buttons //////////
.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: $gap-small;
}

///////// Dropdowns //////////

.dropdown {
  &.show {
    .dropdown-menu {
      display: block;
    }
  }
}

///////// Alerts //////////
.card {
  background-color: $light-gray;
}

///////// Alerts //////////

.alert {
  border: none;

  .btn {
    border: none;
  }

  button.close {
    font-weight: $font-weight;
    font-size: 18px;
    text-shadow: none;
    opacity: 1;
    color: $bg-white;
    outline: none;
    padding: 0;
    position: static;
  }
}

///////// Font Awesome //////////

.navbar {
  .fa {
    &.fa-lg {
      line-height: 19px;
    }
  }
}


////////// Headings //////////

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  small,
  .small {
    font-weight: $font-weight;
    font-size: 75%;
    color: $light-gray; //text-muted
  }
}


// Code
// -----------------

code {
  font-size: 85%;
  background-color: rgba($primary, 0.3);
  color: $success;
  padding: 2px;
  border-radius: $border-radius;
}

pre {
  display: block;
  padding: 6.5px;
  margin: 0 0 calc(1rem / 2);
  font-size: 13px;
  line-height: 1rem;
  word-break: break-all;
  word-wrap: break-word;
  color: $pre-color;
  background-color: $white;
  border: 1px solid $light-gray;
  border-radius: $border-radius;
  white-space: pre-line; /* css-3 */

  // Account for some code outputs that place code tags in pre tags
  code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre-wrap;
    background-color: transparent;
    border-radius: 0;
  }
}

//
// Font Awesome
// --------------------------------------------------

.circle {
  .fa {
    vertical-align: baseline;
  }
}


///// Toastify /////
.Toastify {
  .Toastify__toast {
    font-family: Montserrat, sans-serif;
    border-radius: $border-radius;
    font-weight: bold;

    &.Toastify__toast--success {
      background: $success;
    }

    &.Toastify__toast--warn {
      background: $warning;
    }

    &.Toastify__toast--error {
      background: $danger;
    }

    &.Toastify__toast--info {
      background: $info;
    }

    &-body {
      text-align: center;
      color: $white;

      button {
        background-color: $primary;
        border-color: $primary;
        color: $white;
        &:hover {
          border-color: transparent;
        }
      }
    }
  }

  .notifications-close {
    height: $font-size-base;
    color: $secondary;

    &:hover {
      color: lighten($secondary, 10%);
    }
  }
}




pre code {
  color: $black;
}

.modal {
  .modal-body,
  .modal-header,
  .modal-footer {
    background: #1b1e3c;
    color: $black;
  }
  .modal-header {
    border-bottom: 1px solid $bg-custom-dark;
  }
  .modal-footer {
    border-top: 1px solid $bg-custom-dark;
  }
}