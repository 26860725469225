@import "../../styles/app";

.root {
  @extend %page-base;
  background-color: $bg-white;
  border-radius: 2.75rem 2.75rem 2.75rem 2.75rem;
  .content {
    @extend %page-content;
    display: grid;
    grid-template-columns: 1fr 1fr;
    // grid-template-rows: auto;
    grid-auto-rows: auto;
    grid-template-areas:
      'cardProfile cardAssociatedProfile'
      'cardProfile cardAssociatedProfile'
      'cardProfile cardAssociatedProfile'
      'cardTiersAccount cardEnedis'
      'cardCompany cardPreference'
      'cardSubscription cardSubscription';
    column-gap: 4rem;
    row-gap: $gap-base;
    align-self: center;
    background-color:  $bg-white;
    padding-top: 1rem;

    .cardProfile {
      grid-area: cardProfile;
    }

    .cardAssociatedProfile {
      grid-area: cardAssociatedProfile;
    }

    .cardTiersAccount {
      grid-area: cardTiersAccount;
    }

    .cardEnedis{
      grid-area: cardEnedis;
    }

    .cardSubscription{
      grid-area: cardSubscription;
    }

    .cardCompany{
      grid-area: cardCompany;
    }

    .cardPreference{
      grid-area: cardPreference;
    }
  }
}



