/**
 * Customized version of bootstrap using variables from _variables.scss.
 * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.
 */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');
@import '../../node_modules/bootstrap/scss/functions';
@import 'variables';
@import 'custom-variables';
@import '../../node_modules/bootstrap/scss/bootstrap';
@import '../../node_modules/bootstrap/scss/maps';
@import '../../node_modules/font-awesome/scss/font-awesome';
@import '../../node_modules/line-awesome/dist/line-awesome/css/line-awesome.css';
@import '../../node_modules/animate.css/animate';
@import '../../node_modules/react-toastify/dist/ReactToastify.css';
@import 'mixins';
@import 'base';
@import 'overrides';
@import 'general';
@import 'utils';




