@import "../../../../../../styles/app";

.container {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  gap: $gap-base;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    background-color: $bg-white;
    width: 100%;
    .title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: $title3;
      font-weight: bold;
      color: $secondary;
    }
  }

  .btnAdd {
    width: 30%;
    background-color: $primary;
    border: 2px solid $primary;
    padding: 1rem;
  }
  
  .contentAdd {
    display: flex;
    flex-direction: column;
    gap: $gap-base;
    flex-grow: 1;
    overflow-y: auto;
    padding: 0rem 1rem 0rem 0rem;
    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 0.5rem;
      .formGroup {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-bottom: 0 !important;
        .priceInput {
          display: flex;
          flex-direction: row;
          justify-content:end;
          .priceText {
            border-radius: 2rem 0rem 0rem 2rem;
            width: 65%;
          }
          .priceUnity {
            display: flex;
            flex-direction: column;
            justify-content: end;
            border-radius: 0rem 2rem 2rem 0rem;
            width: 35%;
          }
        }
        .priceMonthInput {
          display: flex;
          flex-direction: row;
          justify-content:end;
          .priceText {
            border-radius: 2rem 0rem 0rem 2rem;
            width: 82%;
          }
          .priceUnity {
            display: flex;
            flex-direction: column;
            justify-content: end;
            border-radius: 0rem 2rem 2rem 0rem;
            width: 18%;
          }
        }
      }
      .checkBox {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;
        padding-left: 0.5rem;
        margin-bottom: 0 !important;
      }
      .contract {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 1rem;
        .formGroup {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          margin-bottom: 0 !important;
        }
      }
    }
    .btn{
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }

  .timeline {
    position: relative;
    border-left: 2px solid $primary;
    overflow-y: scroll;
    scrollbar-width: none;
    height: 30rem;
    .timelineItem {
      display: flex;
      align-items: flex-start;
      position: relative;
      .timelineDot {
        width: 10px;
        height: 10px;
        background-color: $primary; // Point bleu
        border-radius: 50%;
        position: absolute;
        left: -6px; // Centré sur la ligne
        top: 8px;
      }
      .timelineContent {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;
        margin-bottom: 1rem;
        width: 95%;
        gap: 1rem;
      }
    }
  }
}

