@import "../../../../../../../../styles/app";

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: $gap-base;
  position: relative;

  .background_container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../../../../../../../../assets/enedis/congratulations.png');
    background-repeat: no-repeat;
    background-size: cover;
    animation: background_image 0.5s ease-in-out;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 1;
    animation: description 0.75s ease-in-out;

    .description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: $title4;
      font-weight: bold;
    }
  }

  .control {
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 2;
    gap: $gap-base;
    button {
      width: 25%;
    }
  }
}

@keyframes background_image {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes description {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
