.chart-container {
  position: relative;
}

@media (min-width: 200px) and (max-width: 480px) {
  .chart-container {
    height: 25vh;
    width: 80vw;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .chart-container {
    height: 25vh;
    width: 85vw;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  .chart-container {
    height: 30vh;
    width: 60vw;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  .chart-container {
    height: 30vh;
    width: 65vw;
  }
}
@media (min-width: 1201px) and (max-width: 1400px) {
  .chart-container {
    height: 40vh;
    width: 70vw;
  }
}
@media (min-width: 1401px) {
  .chart-container {
    height: 40vh;
    width: 75vw;
  }
}