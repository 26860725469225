@import "../../styles/app";

.root {
  @extend %page-base;
  .content {
    @extend %page-content;
    .message {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $title5;
      font-weight: bold;
      color: $secondary;
      height: 20%;
    }
  }
}
