@import '../../../../styles/app.scss';

.root {
  display: flex;
  flex-direction: column;
  background-color: $bg-white;
  border: solid 2px $bg-white;
  border-radius: $border-radius;
  padding: 1rem;
  gap: $gap-base;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: $gap-small;
    .title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: $secondary;
      gap: $gap-small;
      > span:last-child {
        font-size: $font-size-base;
        font-weight: bold;
      }
    }
  }
  .content {
    display: flex;
    justify-content: center;
    .chart {
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 65%;
      width: 65%;
      .item {
        .not_value {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          background-color: $bg-white;
          border-radius: 2rem;
          padding: 0.5rem 0rem 0.5rem 0rem;
          font-size: clamp($font-size-base, 1vw, $title5);
          color: $no_values;
          gap: $gap-small;
          width: 100%;
          height: 2.5rem;
        }
      }
      .item:first-child {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        .value {
          display: flex;
          flex-direction: row;
          font-size:  $title2;
          font-weight: 300;
          color: $secondary;
        }
      }
      .item:last-child {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        .value {
          padding-left: 1rem;
          display: flex;
          flex-direction: row;
          font-size: $title5;
          font-weight: 300;
          color: $gray;
        }
      }
    }
  }
  .footer {
    display: flex;
    justify-content: center;
    color: $secondary;
    .description {
      color: $secondary;
      margin-bottom: 0rem;
      text-align: center;
    }
  }
}
