@import "../../../../../../../../styles/app";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $gap-base;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex: 2;
    .invalid_msg {
      padding-top: 0.5rem;
      display: flex;
      justify-content: center;
      color: $danger;
      font-weight: bold;
    }
    .formGroup {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 60%;
      .label {
      }
      .input {
        border: none;
      }
    }
  }

  .control {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: $gap-base;
    button {
      width: 25%;
    }
  }
  
}

