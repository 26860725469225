@import "../../../../../../styles/app";

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.5rem 2rem 1.5rem 2rem;
  border-radius: 1.5rem;
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .title {
      color: $secondary;
      font-size: $title3;
      font-weight: bold;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: $gap-base;
    .form {
      display: flex;
      flex-direction: column;
      gap: $gap-small;
      .formGroup {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-bottom: 0rem !important;
        .label {
          color: $secondary;
          margin-bottom: 0rem !important;
        }
        .input {
          width: 100%;
          &.readOnly {
            border: 1px solid $light-gray;
            box-shadow: none;
            background-color: $bg-white;
            cursor: default;
          }
        }
        .resultAddress {
          display: flex;
          position: absolute;
          background-color: $white;
          z-index: 10;
          top: 5rem;
          left: 1rem;
          .resultCollapse {
            border: 1px solid $secondary;
            .result {
              max-height: 150px;
              min-height: auto;
              overflow-y: auto;
              .listItem0 {
                cursor: pointer;
                font-weight: bold;
                color: $secondary;
                border: none;
                border-bottom: 1px inset $light-gray;
              }
              .listItem {
                cursor: pointer;
                color: $secondary;
                border: none;
                border-bottom: 1px inset $light-gray;
              }
            }
          }
        }
        .resultCity {
          display: flex;
          position: absolute;
          background-color: $white;
          z-index: 10;
          top: 5rem;
          left: 1rem;
          .resultCollapse {
            border: 1px solid $secondary;
            .result {
              max-height: 150px;
              min-height: auto;
              overflow-y: auto;
              .listItem {
                color: $secondary;
                border: none;
                border-bottom: 1px inset $light-gray;
              }
            }
          }
        }
      }
      .group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 0.5rem;
        .formGroup {
          display: flex;
          flex-direction: column;
          position: relative; 
          .label {
            color: $secondary;
            margin-bottom: 0rem !important;
          }
        } 
      }
    }
    .summary {
      padding: 1rem;
      > ul {
        display: flex;
        flex-direction: column;
        padding-left: 2rem;
        > li {
          list-style: disc;
        }
      }

    }
    .control {
      display: flex;
      flex-direction: column;
      gap: $gap-base;
      padding: 1rem 0.5rem;
      .deleteControl {
        display: flex;
        flex-direction: row;
        justify-content: center;
        .btn {
          width: 100%;
          &:hover {
            background-color: $red-transparent;
          }
        }

      }    
      .rowControl {
        display: flex;
        flex-direction: row;
        justify-content:  space-between;
        gap: 1rem;
        .btn {
          width: 100%;
        }
      }
      
      
    }
  }
}
