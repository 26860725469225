@import '../../styles/app';

.layout {
  display: grid;
  grid-template-columns: 0.12fr 1fr;
  grid-template-rows: minmax(min-content, max-content) 1fr;
  grid-template-areas:
    'logo main'
    'sidebar main';
  column-gap: $gap-base;
  margin: auto;
  padding: 1rem 1rem 1rem 1rem;
  @media (min-width: 200px) and (max-width: 480px) {
    grid-template-columns: 1fr;
    grid-template-rows: minmax(min-content, max-content);
    grid-template-areas:
      'headerMobile'
      'sidebarMobile'
      'main';
    padding: 0.5rem 0rem 0.5rem 0rem;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: minmax(min-content, max-content);
    grid-template-areas:
      'headerMobile'
      'sidebarMobile'
      'main';
    padding: 0.5rem 0rem 0.5rem 0rem;
  }
  @media (min-width: 769px) and (max-width: 992px) {
  }
  @media (min-width: 993px) and (max-width: 1200px) {
  }
  @media (min-width: 1201px) and (max-width: 1400px) {
  }
  @media (min-width: 1401px) {
  }

  .logo {
    grid-area: logo;
    @media (min-width: 200px) and (max-width: 480px) {
      display: none;
    }
    @media (min-width: 481px) and (max-width: 768px) {
      display: none;
    }
    @media (min-width: 769px) and (max-width: 992px) {
    }
    @media (min-width: 993px) and (max-width: 1200px) {
    }
    @media (min-width: 1201px) and (max-width: 1400px) {
    }
    @media (min-width: 1401px) {
    }
  }

  .headerMobile {
    grid-area: headerMobile;
    @media (min-width: 200px) and (max-width: 480px) {
    }
    @media (min-width: 481px) and (max-width: 768px) {
    }
    @media (min-width: 769px) and (max-width: 992px) {
      display: none;
    }
    @media (min-width: 993px) and (max-width: 1200px) {
      display: none;
    }
    @media (min-width: 1201px) and (max-width: 1400px) {
      display: none;
    }
    @media (min-width: 1401px) {
      display: none;
    }
  }

  .sidebar {
    grid-area: sidebar;
    @media (min-width: 200px) and (max-width: 480px) {
      display: none;
    }
    @media (min-width: 481px) and (max-width: 768px) {
      display: none;
    }
    @media (min-width: 769px) and (max-width: 992px) {
    }
    @media (min-width: 993px) and (max-width: 1200px) {
    }
    @media (min-width: 1201px) and (max-width: 1400px) {
    }
    @media (min-width: 1401px) {
    }
  }

  .sidebarMobile {
    grid-area: sidebarMobile;
    display: none;
    @media (min-width: 200px) and (max-width: 480px) {
      display: flex;
    }
    @media (min-width: 481px) and (max-width: 768px) {
      display: flex;
    }
    @media (min-width: 769px) and (max-width: 992px) {
      display: none;
    }
    @media (min-width: 993px) and (max-width: 1200px) {
      display: none;
    }
    @media (min-width: 1201px) and (max-width: 1400px) {
      display: none;
    }
    @media (min-width: 1401px) {
      display: none;
    }
  }

  .main {
    grid-area: main;
    @media (min-width: 200px) and (max-width: 480px) {
    }
    @media (min-width: 481px) and (max-width: 768px) {
    }
    @media (min-width: 769px) and (max-width: 992px) {
    }
    @media (min-width: 993px) and (max-width: 1200px) {
    }
    @media (min-width: 1201px) and (max-width: 1400px) {
    }
    @media (min-width: 1401px) {
    }
  }
}
