@import "../../../../styles/app";

.timeLineCard {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: $black;
  @media (min-width: 200px) and (max-width: 480px) {
    width: 100%;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    width: 100%;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 90%;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    width: 85%;
  }
  .eventTime {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 20%;
    padding-top: 0.75rem;
    @media (min-width: 200px) and (max-width: 480px) {
      display: none;
    }
    @media (min-width: 481px) and (max-width: 768px) {
      display: none;
    }
    @media (min-width: 769px) and (max-width: 1024px) {
      display: none;
    }
    @media (min-width: 1025px) and (max-width: 1200px) {
      display: none;
    }
    > .date {
      font-size: $font-size-base;
    }
  }

  .timeline {
    display: flex;
    justify-content: center;
    width: 60px;
    .timebar {
      position: relative;
      min-height: 100%;
      top: 1.2rem;
      left: 50%;
      width: 8px;
      background-color: $light-gray;
    }

    .eventIcon {
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;    
      font-size: $title4;
      width: 50px;
      height: 50px;
      background-color: $white;
      border: 7px solid $white;
      border-radius: 50%;
      
      &.eventIconDanger {
        background-color: $danger;
        border-color: lighten($danger, 7%);
        .picto {
          fill: $white;
          stroke: $white;
        }
      }

      &.eventIconWarning {
        background-color: $warning;
        border-color: lighten($warning, 7%);
        .picto {
          fill: $white;
          stroke: $white ;
        }
      }

      &.eventIconSuccess {
        background-color: $success;
        border-color: lighten($success, 7%);
        .picto {
          fill: $primary;
          stroke: $primary;
        }
      }

      &.eventIconInfo {
        background-color: $info;
        border-color: lighten($info, 7%);
        .picto {
          fill: $white;
          stroke: $white;
        }
      }

      &.eventIconPrimary {
        background-color: $primary;
        border-color: lighten($primary, 7%);
        .picto {
          fill: $primary;
          stroke: $primary;
        }
      }

      &.eventIconSecondary {
        background-color: $light-gray;
        border-color: $light-gray;
        .picto {
          fill: $gray;
          stroke: $light-gray;
        }
      }

      &.eventIconDanger,
      &.eventIconWarning,
      &.eventIconSuccess,
      &.eventIconInfo,
      &.eventIconPrimary {
        fill: $white;
        stroke: $white;
      }
    }
  }

  .arrow {
    display: inline-block;
    position: relative;
    top: 15px;
    left: 0rem;
    height: 0;
    width: 0;
    border-top: 10px solid transparent;
    border-right: 10px solid white;
    border-bottom: 10px solid transparent;
  }

  .card {
    background: white;
    border-radius: $border-radius;
    box-shadow: $shadow;
    width: 80%;
    padding: 1rem;
    @media (min-width: 200px) and (max-width: 480px) {
      width: 90%;
    }
    @media (min-width: 481px) and (max-width: 768px) {
      width: 90%;
    }
    @media (min-width: 769px) and (max-width: 1024px) {
      width: 90%;
    }
    @media (min-width: 1025px) and (max-width: 1200px) {
      width: 90%;
    }

    .header {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        'location .'
        'label .'
        'time .';
      column-gap: $gap-base;
      .location {
        grid-area: location;
        display: flex;
        font-size: $title4;
        font-weight: 600;
        overflow: hidden;
        white-space: pre;
        .name {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: $secondary;
          gap: $gap-small;
          .picto {
            display: flex;
            align-items: center;
            fill: $secondary;
            width: 2rem;
            height: 2rem;
            padding: 0.25rem;
          }
        }
      }
      .label {
        grid-area: label;
        display: flex;
        font-weight: bold;
        overflow: hidden;
        color: $secondary;
      }
      .time {
        grid-area: time;
        display: flex;
      }
    }

    .content {
      display: grid;
      grid-template-columns: 3fr 1fr;
      grid-template-areas:
        "left right";
      @media (min-width: 200px) and (max-width: 768px) {
        grid-template-columns: auto;
        grid-template-areas:
        "left"
        "right";
      }      
      .left {
        grid-area: left;
        display: flex;
        flex-direction: column;
        .alert_time {
          display: flex; 
          flex-direction: column;        
          margin-top: 1rem;
          .start {
            display: flex;
            flex-direction: row;
            white-space: pre;
            .time_start {
              display: flex;
              flex-direction: row;
              > span {
                display: block;
                min-width: 55px;
              }
            }
          }

          .end {
            display: flex;
            flex-direction: row;
            white-space: pre;
            @media (min-width: 200px) and (max-width: 768px) {
              flex-direction: column;
            }
            .time_end {
              display: flex;
              flex-direction: row;
              > span {
                display: block;
                min-width: 55px;
              }
            }
            .duration {
              display: flex;
              flex-direction: row;
              > span {
                @media (min-width: 200px) and (max-width: 768px) {
                  display: block;
                  min-width: 55px;
                }
              }
              .separator {
                @media (min-width: 200px) and (max-width: 768px) {
                  display: none;
                }
              }
            }
          }
        }
        
        .time {
          display: none;
          @media (min-width: 200px) and (max-width: 768px) {
            display: none;
          }
          @media (min-width: 769px) and (max-width: 1024px) {
            display: flex;
            justify-content: space-between;
            font-size: $font-size-base;
            &.timeAgo {
              padding-right: 1rem;
            }
          }
          @media (min-width: 1025px) and (max-width: 1200px) {
            display: flex;
            justify-content: space-between;
            font-size: $font-size-base;
            &.timeAgo {
              padding-right: 1rem;
            }
          }
          @media (min-width: 1201px) {
            display: none;
          }
        }
      }

      .right {
        grid-area: right;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        @media (min-width: 200px) and (max-width: 768px) {
          width: 100%;
          justify-content: center;
          padding-top: 1rem;
        }
      }
    }

    .collapse {
      .chart {
        margin-top: 1rem;
      }
      .detail {
        margin-top: 1rem;
        width: 100%;
        font-weight: normal;
        white-space: pre-line;
        > ul {
          padding-top: 1rem;
          padding-left: 2rem;
          > li {
            list-style-type: disc;
          }
        }
      }
    }
  }
}

.noAlert {
  display: flex;
  justify-content: center;
  padding: 2rem;
  font-size: $title4;
  font-weight: bold;
}
