@import '../../../../styles/app.scss';


.root {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: $gap-base;
  background-color: $bg-app;
  border: 2px solid $bg-app;
  border-radius: $border-radius;
  width: 30%;
  @media (min-width: 200px) and (max-width: 480px) {
    width: 100%;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    width: 100%;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    width: 100%;
  }
  @media (min-width: 993px) and (max-width: 1210px) {
    width: 100%;
  }
  @media (min-width: 1211px) and (max-width: 1400px) {
    width: 45%;
  }
  @media (min-width: 1401px) and (max-width: 1750px) {
    width: 40%;
  }
  .header {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: $gap-small;
    .options {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 2rem;
      .btnModify {
        display: flex;
        flex-direction: row;
        background-color: $bg-app;
        color:$primary;
        border: transparent;
        gap: $gap-small;
        &:hover {
          background-color: $bg-white;
        }
      }
      .toggleButton {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        bottom: 1.5rem;
        cursor: pointer;
        .background {
          border: 2px solid $bg-app;
          display: flex;
          width: 9rem;
          padding: 0.5rem;
          align-items: flex-start;
          background-color: $bg-white;
          gap: $gap-small;
          border-radius: 5.3125rem;
          .label {
            width: 48%;
            display: flex;
            padding: 1.0625rem 0.625rem 1.125rem 0.625rem;
            justify-content: center;
            align-items: center;
            border-radius: 5.3125rem;
            transition: background-color 0.3s ease, transform 0.3s ease;
            cursor: pointer;
            &.on {
              background-color: $light-green;
              transform: translateX(0);
              color: $bg-white;
            }
            &.off {
              background-color: $danger;
              transform: translateX(105%);
              color: $bg-white;
            }
          }
        }
      }
      .btnDelete {
        display: flex;
        flex-direction: row;
        background-color: $bg-app;
        color: $danger;
        border: transparent;
        gap: $gap-small;
        &:hover {
          background-color: $danger-transparent;
        }
      }
      .btnDisable{
        display: flex;
        flex-direction: row;
        background-color: $bg-app;
        color:$gray-600;
        border: transparent;
        gap: $gap-small;
        &:hover {
          cursor: not-allowed;
        }
      }
    }
    .title {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .name {
        font-size: $title2;
        font-weight: 700;
        color: $secondary;
      }
      .localisation {
        display: flex;
        flex-direction: row;
        gap: $gap-small; 
      }
    }
    .listLabel {
      display: flex;
      flex-direction: row;
      gap: $gap-high;
      .label {
        display: flex;
        flex-direction: row;
        background-color: $bg-white;
        color: $primary;
        justify-content: space-between;
        align-items: center;
        padding: 0.25rem 0.5rem;
        gap: $gap-small;
        border-radius: $border-radius;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: $gap-base;
    .action {
      display: flex;
      flex-direction: column;
      background-color: $bg-white;
      width: 100%;
      border-radius: $border-radius;
      padding: 1rem;
      gap: $gap-base;
      .title {
        display: flex;
        justify-content:flex-start;
        font-weight: 700;
        color: $primary;
      }
      .infos {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .info {
          padding: 1rem;
          background-color: $bg-app;
          border-radius:  1.25rem;;
        }
      }
    }
    .trigger {
      display: flex;
      flex-direction: column;
      background-color: $bg-white;
      width: 100%;
      border-radius: $border-radius;
      padding: 1rem;
      gap: $gap-base;
      .title {
        display: flex;
        justify-content:flex-start;
        font-weight: 700;
        color: $primary;
      }
      .infos {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .info {
          padding: 1rem;
          background-color: $bg-app;
          border-radius:  1.25rem;;
        }
      }
    }
    .dropdown {
      display: flex;
      padding: 0.5rem 1rem;
      flex-direction: column;
      align-items: flex-start;
      gap: $gap-base;
      align-self: stretch;
      border-radius: $border-radius;
      border: 2px solid $primary;
      .layout {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: $black;
        width: 100%;
        font-weight: 400;
        line-height: 120%; 
        .icon {
          display: flex;
          border-radius: 2.5rem;
          justify-content: center;
          align-items: center;
          height: fit-content;
          transition: transform 0.3s ease;
          &:hover {
            background-color: $primary-transparent ;
          }
          &.open {
            transform: rotate(0deg);
          }
          &.close {
            transform: rotate(180deg);
          }
        }
      }
      .Sensors { 
        display: flex;
        flex-direction: column;
        gap: $gap-base;
        transition: transform 0.3 ease;
        &.showSensors {
          visibility: visible;
        }
        &.hideSensors {
          display: none;
        }
        .sensor {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          gap: $gap-small;
          .title {
            font-size: $title3;
          }
          .infos {
            display: flex;
            flex-direction: column;
            gap: $gap-small;
            .info {
              display: flex;
              flex-wrap: wrap;
            }
          }
        }
      }
    }
  }
  @media(min-width: 0px) and (max-width: 768px) {
    width: 100%;
    max-width: none;
  }
}