/**
 * Customized version of bootstrap using variables from _variables.scss.
 * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.
 */
 @import '../../node_modules/bootstrap/scss/functions';
 @import 'variables';
 @import '../../node_modules/bootstrap/scss/variables';
 @import '../../node_modules/bootstrap/scss/mixins';
 @import 'mixins';
 @import 'extends';

 @font-face {
  font-family: 'MaterialSymbolsRounded';
  src: url('../assets/fonts/MaterialSymbolsRounded-VariableFont_FILL,GRAD,opsz,wght.ttf') format('truetype');
  font-weight: 100 700;
  font-style: normal;
}

.material-symbols-rounded {
  font-family: 'MaterialSymbolsRounded', sans-serif;
  font-variation-settings: 
    'FILL' 0, /* FILL pour déterminer filled ou unfilled */
    'GRAD' 0, /* GRAD pour ajuster le gradient */
    'opsz' 24, /* OPSZ pour la taille optique */
    'wght' 300; /* WGHT pour ajuster l'épaisseur */
    // font-weight: 300;
    // font-style: normal;
    // font-size: 24px;
    display: flex;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
}



 




