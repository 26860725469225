@import "../../../styles/app";

.nav {
  display: flex;
  flex-direction: row;
  flex: 1;
  .nav_h {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: $gap-base;
    @media (min-width: 200px) and (max-width: 480px) {
      display: none;
    }
    @media (min-width: 481px) and (max-width: 768px) {
      display: none;
    }
    @media (min-width: 769px) and (max-width: 992px) {
      display: none;
    }
    @media (min-width: 993px) and (max-width: 1200px) {
      display: none;
    }
    @media (min-width: 1201px) and (max-width: 1400px) {
    }
    @media (min-width: 1401px) {
    }
    .btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 4%;
      background-color: transparent;
      border: 2px solid transparent;
      border-radius: $border-radius;
      padding: 0.75rem 0.5rem 0.75rem 0.5rem;
      &.active {
        opacity: 1;
        border: 2px solid $primary;
        background-color: $bg-white;
      }   
    }
    .btn:active {
      border: none;
      outline: none;
      background-color: transparent;
    }
    .btn:hover {
      border: 2px solid $primary;
    }
    .menu {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: $bg-app;
      padding: 0.5rem;
      border-radius: 1.75rem 1.75rem 1.75rem 1.75rem;
      flex: 1.25;
      gap: $gap-base;
      .btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: transparent;
        border: 2px solid transparent;
        border-radius: $border-radius;
        padding: 0.75rem 0.5rem 0.75rem 0.5rem;
        gap: $gap-small;
        .item {
          display: flex;
          align-items: center;
          font-size: $font-size-base;
          color: $secondary;
        }
        &.active {
          opacity: 1;
          border: 2px solid $primary;
          background-color: $bg-white;
          .item {
            color: $primary;
          }
        } 
        &.disabled {
          opacity: 1;
        }   
      }
      .btn:active {
        border: none;
        outline: none;
        background-color: transparent;
      }
      .btn:hover {
        border: 2px solid $primary;
        .item {
          display: flex;
          align-items: center;
          font-size: $font-size-base;
          color:  $primary;
        }
      }  
    }

  }
  .nav_v {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: $bg-app;
    padding: 0.5rem;
    border-radius: 1.75rem;
    gap: $gap-small;
    @media (min-width: 200px) and (max-width: 480px) {
      display: flex;
    }
    @media (min-width: 481px) and (max-width: 768px) {
      display: flex;
    }
    @media (min-width: 769px) and (max-width: 992px) {
      display: flex;
    }
    @media (min-width: 993px) and (max-width: 1200px) {
      display: flex;
    }
    @media (min-width: 1201px) and (max-width: 1400px) {
      display: none;
    }
    @media (min-width: 1401px) {
      display: none;
    }
    .input {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: $bg-white;
      border-radius: $border-radius;
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      gap: $gap-small;
      box-shadow: none;
      .option {
        display: flex;
        align-items: center;
        font-size: $font-size-base;
        color: $secondary;
      }
    }
  }
}

