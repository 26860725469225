@import "../../../../../../styles/app";

.root {
  display: flex;
  flex-direction: column;
  border-radius: 1.5rem 1.5rem 1.5rem 1.5rem;
  background-color: $bg-app;
  max-width: 50.75rem;
  height: 100%;
  padding: 1rem;
  gap: $gap-base;
  border: 2px solid $bg-app;
  transition: border-color 0.2s ease;
  &:hover {
    border: solid 2px $primary;
    .header .control .delete {
      opacity: 1;
    }
    .header .control .modify {
      opacity: 1;
    }
  }
  @media (min-width: 200px) and (max-width: 480px) {
    max-width: 100%;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    max-width: 100%;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    max-width: 100%;
  }
  @media (min-width: 993px) and (max-width: 1200px) {
  }
  @media (min-width: 1201px) and (max-width: 1400px) {
    max-width: 48% ;
  }
  @media (min-width: 1401px) {
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 1.5rem 1.5rem 0 0;
    .title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: $title3;
      font-weight: bold;
      color: $secondary;
    }
    .control {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 0.5rem;
      .delete {
        border-radius: 2rem;
        padding: 0.5rem;
        opacity: 0; 
        transition: opacity 0.2s ease, transform 0.5s ease;
        &:hover {
          background-color: $danger-transparent;
        }
      }
      .modify {
        border-radius: 2rem;
        padding: 0.5rem;
        opacity: 0; 
        transition: opacity 0.2s ease, transform 0.5s ease;
        &:hover {
          background-color: $bg-white;
        }
      }
    }
  }
  .content {
    display: flex;
    flex-direction: row;
    margin-bottom: 0rem;
    width: 100%;
    gap: $gap-small;
    padding: 0.5rem;
    @media (min-width: 350px) and (max-width: 481px) {
      flex-wrap: wrap;
    }
    @media (min-width: 481px) and (max-width: 768px) {
      flex-wrap: wrap;
    }
    @media (min-width: 769px) and (max-width: 992px) {
      flex-wrap: wrap;
    }
    @media (min-width: 993px) and (max-width: 1200px) {
      flex-wrap: wrap;
    }
    @media (min-width: 1201px) and (max-width: 1400px) {
      flex-wrap: wrap;
    }
    @media (min-width: 1401px) {
      flex-wrap: wrap;
    }
    .label {
      display: flex;
      flex-direction: row;
      gap: $gap-small;
      background-color: $bg-white;
      padding:  0.5rem 1rem;
      border-radius: $border-radius;
      >span{
        color: $secondary;
      }
    }
  }
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    border-radius: 0 0 1.5rem 1.5rem;
    background-color: $white;
  }
}
